export const data = [
    {
        title: "Lista de Tarefas Interativa",
        description: "Crie um aplicativo web que permita aos usuários adicionar, remover e marcar tarefas como concluídas, com atualizações em tempo real.",
        route: "/task"
    },
    {
        title: "Calculadora Online",
        description: "Desenvolva uma calculadora que execute operações matemáticas básicas, como adição, subtração, multiplicação e divisão."
    },
    {
        title: "Aplicativo de Notas",
        description: "Crie um aplicativo de anotações onde os usuários possam criar, editar e excluir notas, com suporte a formatação de texto."
    },
    {
        title: "Galeria de Imagens com Filtro",
        description: "Construa uma galeria de imagens que permita aos usuários filtrar as imagens com base em categorias ou tags."
    },
    {
        title: "Conversor de Unidades",
        description: "Desenvolva um conversor de unidades que converta valores entre diferentes unidades de medida, como comprimento, peso e temperatura."
    },
    {
        title: "Quiz Interativo",
        description: "Crie um quiz de perguntas e respostas com várias opções, fornecendo feedback imediato sobre as respostas dos usuários."
    },
    {
        title: "Relógio Mundial",
        description: "Crie um relógio que mostre a hora atual em diferentes fusos horários ao redor do mundo."
    },
    {
        title: "Aplicativo de Previsão do Tempo",
        description: "Desenvolva um aplicativo que utilize uma API de previsão do tempo para mostrar as condições climáticas atuais e futuras."
    },
    {
        title: "Gerador de Senhas",
        description: "Crie um gerador de senhas que permita aos usuários personalizar o comprimento e os tipos de caracteres das senhas geradas."
    },
    {
        title: "Aplicativo de Contagem Regressiva",
        description: "Construa um aplicativo que permita aos usuários definir uma data e hora e exiba uma contagem regressiva até o evento."
    },
]