import { useDispatch } from "react-redux"
import { setCategoriesTasks } from "../../store/tasks/taskReducer";
import useLoadCategoryTasks from "../category/useLoadCategoryTasks";

export default function useCategoryTasks() {

    const loadCategoryTasks = useLoadCategoryTasks()
    const dispatch = useDispatch();

    return async () => {

        try {
            const response = await loadCategoryTasks()
            dispatch(setCategoriesTasks(response?.data))
        } catch (error) {

            console.log("🚀 ~ file: useCategoryTasks.ts:17 ~ return ~ error:", error);

            throw error            
        }
    }
}