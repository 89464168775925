import { createSlice } from "@reduxjs/toolkit";
import { userQuote } from "../@types/Task/UserQuote";

type InitialState = {
    data: userQuote[],
    results: number | null

    pagination: {
        pages: number | null,
        currentPage: number,
        offset: number,
        limit: number 
    }

    loading: boolean
    loaded: boolean

}

const initialState: InitialState = {
    data: [],
    results: null,

    pagination: {
        pages: null,
        currentPage: 1,
        offset: 0,
        limit: 20
    },

    loaded: false,
    loading: false
}

const appSlice = createSlice({
    name: "userQuotesReducer",
    initialState,
    reducers: {

        setData: (state, action) => {
            const { data, results } = action.payload
            state.data = data
            state.results = results

            const limit = 20

            

        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLoaded: (state, action ) => {
            state.loaded = action.payload
        },
        setPagination: (state, action) => {

            const { pages, currentPage, offset, limit } = action.payload

            state.pagination.pages = pages 
            state.pagination.currentPage = currentPage 
            state.pagination.offset = offset 
            state.pagination.limit = limit 
        }
    }
})


export const { setData, setLoaded, setLoading, setPagination } = appSlice.actions

export default appSlice.reducer

export interface IUSerQuote extends InitialState { }
