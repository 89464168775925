import React from "react"
import { ReactElement } from "react"
import style from "./List.module.scss"
import classNames from "classnames"

type Props = {
    array?: any[],
    component: ReactElement,
    dataKey: string,
    itemMargin?: SizeProps
    componentProps?: {}
    limit?: number
    className?: string
    loading?: boolean
}

export default function List({
    array,
    component,
    dataKey,
    componentProps,
    itemMargin,
    limit,
    className,
    loading
}: Props) {

    let arr = array
    if (!array) return null
    if (limit) arr = array.slice(0, limit)

    return <>
        <ul className={classNames({ [style["list"]]: true, [`${className}`]: className })}>

            {loading
                ? <>Loading</>
                : arr?.map((item, index) => {
                    if (limit) {
                        array.slice(0, limit)
                    }
                    if (array.length == 0) {
                        return (
                            <div>
                                Nada a ser exibido aqui
                            </div>
                        )
                    }

                    return (
                        <li
                            key={index}
                            className={style["item"]}
                            data-margin={itemMargin}
                        >
                            {React.cloneElement(component, {
                                [dataKey]: item,
                                index: index + 1,
                                ...componentProps,
                            })}
                        </li>
                    )
                })}
        </ul>
    </>
}