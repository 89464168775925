import { ReactNode, useEffect, useState } from "react";
import List from "../../components/List";
import Container from "../../components/container";
import PageTemplate from "../../components/pageTemplate/intex";
import HomeConversor from "./homeConversor";
import { useDispatch, useSelector } from "react-redux";
import { NavOptions, setActiveOption, setOptions } from "../../store/nav/navReducer";
import { Nav } from "../../components/navbar";
import { IStore } from "../../store/IStore";
import AreaCalculator from "./areaCalculator";
import UnityConverter from "./unityConverter";

export default function ConversorView() {
    const dispatch = useDispatch();
    const screens: Screen[] = [
        {
            id: 1,
            component: <HomeConversor />,
            short: "Home",

        },
        {
            id: 2,
            component: <AreaCalculator />,
            short: "Area",

        },
        {
            id: 3,
            component: <UnityConverter />,
            short: "Conversor",

        },

    ]

    let navOptions: NavOptions = [];

    for (const screen of screens) {
        navOptions = [
            ...navOptions,
            {
                id: screen.id,
                label: screen.short
            }
        ]
    }

    useEffect(() => {
        dispatch(setOptions(navOptions))
        dispatch(setActiveOption(navOptions[0]))
    }, [])



    const activeScreenId = useSelector((state: IStore) => state.nav.activeOption?.id)


    const activeOption = screens.find((s => s.id === activeScreenId)) as Screen

    return (
        <PageTemplate>
            <Container
                title="Conversor de unidades"
                navHeading={<Nav />}
                leftContent={<LeftContent />}
            >
                <ScreenHolder
                    screens={screens}
                    activeScreen={activeOption}
                />
            </Container>
        </PageTemplate>
    )
}


//todo Isolar componente
type Screen = {
    id: number,
    component: any,
    short: string
}

const ScreenHolder = ({ screens, activeScreen }: { screens: Screen[], activeScreen: Screen }) => {
    if (!activeScreen) return null
    console.log("🚀 ~ file: index.tsx:83 ~ ScreenHolder ~ activeScreen:", activeScreen);


    console.log("🚀 ~ file: index.tsx:83 ~ ScreenHolder ~ screens:", screens);


    const component = screens.find(s => s.id === activeScreen.id)

    return (
        component ? component.component : <>Component nao localizado</>
    )
}


const LeftContent = () => {


    return (
        <List
            array={[{ title: "Conversor de comprimentos" }]}
            component={<Item />}
            dataKey="item"
        ></List>
    )
}

const Item = ({ item }: { item?: { title: string } }) => {

    return <div>{item?.title}</div>
}

