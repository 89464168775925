import { useState } from "react";
import style from "../baseScreens.module.scss";

export default function UnityConverter() {


    const calculateMedida = (medida1: number, medida2: number) => {
        const result = (medida1 * medida2).toFixed(2)

        return result
    }

    const [medida1, setMedida1] = useState(0)
    const [medida2, setMedida2] = useState(0)

    return (
        <>
            <h3>Conversor de unidades</h3>
            <p>Converta as unidades desejadas:</p>

            <br />
            <br />
            <div className={style["form-wrapper"]}>
                <form action="" className={style["form"]}>

                    <h4 className={style["form__title"]}>Converter unidades
                    </h4>

                    <label htmlFor="input-1">Medida 1:</label>
                    <select name="" id="">
                        <option value="">cm</option>
                        <option value="">m</option>
                        <option value="">km</option>
                    </select>
                    <input
                        type="number"
                        onChange={(e) => setMedida1(Number(e.target.value))}
                        min={0}
                        step={.1}
                    />

                    <label htmlFor="input-1">Medida 2:</label>
                    <select name="" id="">
                        <option value="">cm</option>
                        <option value="">m</option>
                        <option value="">km</option>
                    </select>
                    <input type="number" onChange={(e) => setMedida2(Number(e.target.value))} />


                    <label htmlFor="input-1">Resultado:</label>
                    <select name="" id="">
                        <option value="">cm²</option>
                        <option value="">m²</option>
                        <option value="">km²</option>
                    </select>
                    <input value={calculateMedida(medida1, medida2)}>
                    </input>
                </form>
            </div>

        </>
    )

}