import { z } from "zod";
import Container from "../../../components/container";
import Form from "../../../components/form";
import Input from "../../../components/input/Input";
import PageTemplate from "../../../components/pageTemplate/intex";
import List from "../../../components/List";


import { GiAirplaneArrival, GiAirplaneDeparture } from "react-icons/gi";
import { FaUser } from "react-icons/fa6";
import { LuBaby } from "react-icons/lu";
import { MdDateRange } from "react-icons/md";
import { useState } from "react";
import usePost from "../../../hooks/http/usePost";
import useSuccess from "../../../hooks/notices/useSuccess";
import useFail from "../../../hooks/notices/useFail";
import { useParams } from "react-router-dom";
export default function Cotacao() {

    const [oneWay, setOneWay] = useState(true)

    const schema = z.object({
        ticket_origin: z.optional(z.string()),
        ticket_destiny: z.optional(z.string()),
        ticket_origin_date: z.optional(z.string()),
        ticket_destiny_date: z.optional(z.string()),
        adults: z.string(),
        childrens: z.optional(z.string())
    }).or(z.null())

    const marketValues = [
        {
            cia: "gol",
            value: 120,
        },
        {
            cia: "latam",
            value: 120,
        }
    ]

    const style = {
        display: "flex",

    }

    const post = usePost()
    const success = useSuccess();
    const fail = useFail()

    const [loading, setLoading] = useState(false)
    const { id } = useParams()

    const onSubmit = async () => {

        try {
            setLoading(true)
            const response = await post("/ticket", schema)
            success("Cotação criada com sucesso!")
        } catch (error: any) {
            fail(error.message)

        } finally {
            setLoading(false)
        }
    }

    return (
        <PageTemplate
        >
            <Container
                title={id ? "Detalhes da cotação" : "Nova cotação"}
            >
                <div style={style}>
                    <div style={{ flex: 1, padding: "1rem" }}>
                        <input
                            id="only-way"
                            type="radio"
                            checked={oneWay}
                            onClick={() => setOneWay(true)}
                        />
                        <label htmlFor="only-way">Somente Ida</label>
                        <input
                            id="go-and-back"
                            type="radio"
                            checked={!oneWay}
                            onClick={() => setOneWay(false)}
                        />
                        <label htmlFor="go-and-back">Ida e volta</label>
                        {oneWay
                            ?
                            <Form
                                schema={schema}
                                endpoint="ticket"
                                successMessage={id ? "Cotação atualizada com sucesso!" : "Cotação criada com sucesso!"}
                                submitMessage={id ? "Atualizar cotação" : "Criar cotação"}

                                inputs={[
                                    {
                                        direction: "row",
                                        name: "ticket_origin_date",
                                        type: "date",
                                        label: "Data de ida",
                                        icon: <MdDateRange />
                                    },
                                    {
                                        direction: "row",
                                        name: "ticket_origin",
                                        type: "text",
                                        label: "Saindo de",
                                        icon: <GiAirplaneDeparture />
                                    },
                                    {

                                        direction: "row",
                                        name: "ticket_destiny",
                                        type: "text",
                                        label: "Destino",
                                        icon: <GiAirplaneArrival />,
                                    },
                                    {
                                        direction: "row",
                                        name: "adults",
                                        type: "text",
                                        label: "Adultos",
                                        defaultValue: 1,
                                        icon: <FaUser />

                                    },
                                    {
                                        direction: "row",
                                        name: "childrens",
                                        type: "text",
                                        label: "Crianças",
                                        defaultValue: undefined,
                                        icon: <LuBaby />
                                    }

                                ]}
                            />
                            : <Form
                                schema={schema}
                                endpoint="ticket"
                                successMessage="Cotação criada com sucesso!"
                                inputs={[
                                    {
                                        direction: "row",
                                        name: "ticket_origin_date",
                                        type: "date",
                                        label: "Data de ida",
                                        icon: < MdDateRange />
                                    },
                                    {
                                        direction: "row",
                                        name: "ticket_destiny_date",
                                        type: "date",
                                        label: "Data da volta",
                                        icon: < MdDateRange />,
                                    },
                                    {
                                        direction: "row",
                                        name: "ticket_origin",
                                        type: "text",
                                        label: "Saindo de",
                                        icon: < GiAirplaneDeparture />
                                    },
                                    {

                                        direction: "row",
                                        name: "ticket_destiny",
                                        type: "text",
                                        label: "Destino",
                                        icon: <GiAirplaneArrival />,
                                    },
                                    {
                                        direction: "row",
                                        name: "adults",
                                        type: "number",
                                        label: "Adultos",
                                        defaultValue: 1,
                                        icon: <FaUser />,
                                    },
                                    {
                                        direction: "row",
                                        name: "childrens",
                                        type: "number",
                                        label: "Crianças",
                                        defaultValue: undefined,
                                        icon: <LuBaby />,
                                    }
                                ]}
                            >
                            </Form>
                        }
                    </div>
                    <div style={{ flex: 1, padding: "1rem" }}>
                        <h3>Valores de mercado:</h3>
                        <List
                            component={<MarketValue />}
                            dataKey="item"
                            array={marketValues}
                        ></List>
                    </div>
                </div>
            </Container>
        </PageTemplate >
    )
};




type MarketValueProps = {
    item?: any
}
const MarketValue = ({ item }: MarketValueProps) => {


    return (
        <div>
            <>
                {item.cia}
            </>
            <>{item.value}</>
        </div>
    )
}