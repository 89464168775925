import style from './Text.module.scss'

type Props = {
    children: string | undefined
    size?: SizeProps
    type?: TypeProps
}

export default function Text({ children, size, type }: Props) {

    return <p
        className={style["paragraph"]}
        data-size={size ? size : "normal"}
        data-type={type ? type : "primary"}
    >
        {children}
    </p>
}