import axios from "axios";
import ButtonGroup from "../../components/buttonGroup/ButtonGroup";
import style from "./Login.module.scss"
import { useState } from "react";
import useSuccess from "../../hooks/notices/useSuccess";
import usePost from "../../hooks/http/usePost";
import { useDispatch } from "react-redux";
import { isLoggedIn, setSession } from "../../store/account/accountReducer";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { defaultContext, useAuth } from "../../authProvider";

export default function Login() {

    const [email, setEmail] = useState<string | undefined>(undefined)
    const [password, setPassword] = useState<string | undefined>(undefined)

    const success = useSuccess();
    const post = usePost();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { login } = useAuth()

    const submit = async (e: any) => {

        e.preventDefault()

        try {
            if (!email || !password) return null
            const data = {
                email,
                password
            }
            const response = await post("/auth", data)

            const session = {
                token: response.token,
                username: response.data.name,
                email: response.data.email,
                userID: response.data.id
            }
            login(session)

            defaultContext.user = session

            window.sessionStorage.setItem("session", JSON.stringify(session))

            dispatch(setSession(session))
            dispatch(isLoggedIn(true))
            navigate("/")
            return response

        } catch (error: any) {
            console.log(error)
            success(error.message)
        }
    }



    return (
        <div className={style["login-page"]}>
            <div className={style["login-page__form-wrapper"]}>
                <form action="" onSubmit={submit}>
                    <h3>Acessar</h3>

                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="password">Senha:</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <ButtonGroup
                        className={style["login-page__form__controll"]}
                        buttons={[
                            {
                                children: <a onClick={() => navigate("/criar-conta")}>Criar conta</a>,
                                buttonType: "outline-secondary"
                            },
                            {
                                buttonType: "primary",
                                children: "Entrar",
                                onClick: submit
                            },
                        ]}
                    >
                    </ButtonGroup>
                </form>
            </div>
        </div>
    )
}