import { useState } from "react";
import { BiCheck } from "react-icons/bi";
import { Category } from "../../../../@types/Task/Task";
import Button from "../../../../components/button";
import usePost from "../../../../hooks/http/usePost";
import style from "./FormCategory.module.scss";
import usePut from "../../../../hooks/http/usePut";
import { useDispatch } from "react-redux";
import { setCategoriesTasks } from "../../../../store/tasks/taskReducer";

export default function FormCategory({ category, cb }: { category?: Category, cb?: any }) {

    const [createCategory, setCreateCategory] = useState(false)
    const [createCategoryLoading, setCreateCategoryLoading] = useState(false)
    const [newCategoryName, setNewCtegoryName] = useState<string | undefined>(category?.category_text)
    const [newCategoryColor, setNewCategoryColor] = useState<string | undefined>(category?.category_color)

    const post = usePost();
    const put = usePut();
    const dispatch = useDispatch()

    const handleNewCategory = async (e: any) => {
        e.preventDefault()
        try {
            setCreateCategoryLoading(true)

            const data = {
                id: category?.id,
                category_text: newCategoryName,
                category_value: newCategoryName,
                checked: true,
                category_color: newCategoryColor
            }

            if (!category) {
                const response = await post("/task-category", data)

                dispatch(setCategoriesTasks(response))
                if (cb) return cb(response)
                return

            } else {
                const response = await put("/task-category", data)
                dispatch(setCategoriesTasks(response))
                if (cb) return cb(response)
                return cb(response)
            }
        } catch (error) {
            setCreateCategoryLoading(false)

            console.log("🚀 ~ file: index.tsx:45 ~ handleNewCategory ~ error:", error);

            throw error
        } finally {
            setCreateCategoryLoading(false)
            setNewCtegoryName(undefined)
            setNewCategoryColor(undefined)
            setCreateCategory(false)


        }
    }

    return (
        <form
            onSubmit={handleNewCategory}
            id="create-category"
            className={style["form"]}

        >
            <input
                title="new-category"
                type="text"
                className={style["form__name"]}
                placeholder={category ? category.category_text : "Nova categoria"}
                defaultValue={category ? category.category_text : "Nova categoria"}
                value={newCategoryName}
                onChange={(e) => setNewCtegoryName(e.target.value)}
            />
            <input
                type="color"
                onChange={(e) => setNewCategoryColor(e.target.value)}
                className={style["form__color"]}

            />
            <Button
                className={style["form__button"]}
                buttonType="button-icon-2"
                onClick={(e) => handleNewCategory(e)}><BiCheck />
            </Button>
        </form>
    )
}