import { ReactElement } from "react"
import style from "./Modal.module.scss"
import Overlay from "./Overlay"

type Props = {
    children: ReactElement,
    view: boolean,
    setView: any

}

export default function Modal({
    children,
    view,
    setView
}: Props) {

    /* const modal = document.getElementById(`${style["modal"]}`)

    document.addEventListener("click", (e) => {
        if (!modal?.contains(e.target as Node)) {
            setView(false)
        }
    }) */

    return <>
        <div
            id={style["modal"]}
            className={style["modal"]}
            data-view={view}
        >
            {children}
        </div>
        {view && <Overlay setView={setView} />}
    </>
}