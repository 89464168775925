import { useEffect } from "react";
import { IItemList } from "../../../@types/ItemList";
import { SellerOffer } from "../../../@types/SellerOffer";
import List from "../../../components/List";
import { useSelector } from "react-redux";
import { IStore } from "../../../store/IStore";
import useSellerOffers from "../../../hooks/sellerOffers/useSellerOffers";
import style from "../SellMiles.module.scss";


export default function MyOffers() {

    const sellerOffersReducer = useSelector((state: IStore) => state.sellerOffers)
    const offers = sellerOffersReducer.data
    const pagination = sellerOffersReducer.pagination

    const loadUSerOffers = useSellerOffers()

    useEffect(() => {
        (async () => {
            await loadUSerOffers({ limit: pagination.limit, offset: pagination.offset })
        })()
    }, [pagination.currentPage])


    return (
        <div>
            <p>Minhas Ofertas</p>
            <List
                array={offers}
                component={<Component />}
                dataKey="item"
            ></List>
        </div>
    )
};


interface component extends IItemList<SellerOffer> { }


const Component = ({ item }: component) => {


    return (
        <div className={style["item-seller-offer"]}>
            <div>{item?.program}</div>
            <div>${item?.monetary_value}</div>
            <div>{item?.miles_value} milhas</div>
        </div>
    )
}