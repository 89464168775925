import { useState } from "react";
import Container from "../../components/container";
import PageTemplate from "../../components/pageTemplate/intex";
import style from "./SellMiles.module.scss";
import Aside from "./components/aside";
import MyOffers from "./components/myOffers";
import Pagination from "../../components/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../store/IStore";
import { setPagination } from "../../store/sellerOffers";

export type Screen = "sells" | "offers" | "default"

export default function SellMiles() {


    const [screen, setScreen] = useState<Screen>("default")

    const pagination = useSelector((state: IStore) => state.sellerOffers.pagination)
    const dispatch = useDispatch()
    const goTo = (e: number) => dispatch(setPagination({ currentPage: e }))

    return (
        <PageTemplate>
            <Container
                title={"Vender milhas"}
                leftContent={
                    <Aside
                        //@ts-ignore
                        setScreen={setScreen}
                    />
                }
                footer={<Pagination pagination={pagination} goTo={goTo} />}
            >
                <>
                    {
                        screen === "default" && <>

                            <p>Faça dinheiro vendendo suas milhas com a MilhasZen.</p>


                            <p>Média de milhas negociadas</p>


                            <p>Dúvidas antes de vender</p>
                        </>
                    }
                    {

                        screen === "offers" && <MyOffers />
                    }
                    {

                        screen === "sells" && <>Vendas</>
                    }
                </>
            </Container>
        </PageTemplate>
    )
};



