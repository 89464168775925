import { BiBorderAll } from "react-icons/bi";
import { BsPlus, BsFilter, BsCalendar } from "react-icons/bs";
import { LiaSortSolid } from "react-icons/lia";
import ButtonGroup from "../../../components/buttonGroup/ButtonGroup";
import style from "./controlTask.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { IView } from "../../../store/IStore";
import { setModalCreateTask, setTaskView } from "../../../store/tasks/taskReducer";

export function ControlTask({ setCreateTask }: { setCreateTask?: any }) {

    const taskView = useSelector((state: IView) => state.taskView)

    const dispatch = useDispatch();

    return (
        <div className={style["control"]}>
            <ButtonGroup
                buttons={[
                    {
                        title: "Calendário",
                        value: "calendar",
                        buttonType: "button-icon-2",
                        icon: <BsCalendar />,
                        onClick: () => dispatch(setTaskView("calendar"))
                    },
                    {
                        title: "Board",
                        value: "board",
                        buttonType: "button-icon-2",
                        icon: <BiBorderAll />,
                        onClick: () => dispatch(setTaskView("board"))
                    }
                ]}
            />
            <ButtonGroup
                buttons={[
                    {
                        buttonType: "button-icon-2",
                        icon: <BsPlus />,
                        onClick: () => dispatch(setModalCreateTask(true))
                    },
                    {
                        buttonType: "button-icon-2",
                        icon: <BsFilter />
                    },
                    {
                        buttonType: "button-icon-2",
                        icon: <LiaSortSolid />
                    }
                ]}
            />
        </div>
    )
}