import { createSlice } from "@reduxjs/toolkit";
import { SellerOffer } from "../@types/SellerOffer";
import { Pagination } from "../@types/Pagination";


type InitialState = {
    data: SellerOffer[]
    pagination: Pagination
    loading: boolean,
    loaded : boolean
}

const initialState: InitialState = {
    data: [],
    pagination: {
        currentPage: 1,
        limit: 20,
        offset: 0,
        pages: 0,
        results: 0
    },
    loading: false,
    loaded: false
}

const appSlice = createSlice({
    name: "sellerOffersReducer",
    initialState,
    reducers: {

        setData: (state, action) => {
            const { data, results } = action.payload
            state.data = data
            state.pagination.results = results
        },
        setPagination: (state, action) => {

            const { pages, currentPage, offset, limit } = action.payload

            state.pagination = { ...state.pagination, pages }
            state.pagination = { ...state.pagination, currentPage }
            state.pagination = { ...state.pagination, offset }
            state.pagination = { ...state.pagination, limit }
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLoaded: (state, action) => {
            state.loaded = action.payload
        }


    }
})

export const {
    setData, setPagination, setLoaded, setLoading
} = appSlice.actions

export default appSlice.reducer

export interface ISellerOffers extends InitialState { }