import { useEffect, useState } from "react";
import Container from "../../components/container";
import PageTemplate from "../../components/pageTemplate/intex";
import useUserQuotes from "../../hooks/userQuotes/useUserQuotes";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../store/IStore";
import { BiFilter, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../components/buttonGroup/ButtonGroup";
import Pagination from "../../components/pagination/Pagination";
import { setPagination } from "../../store/userQuote";
import Menu from "../../components/menu";
import QuotesList from "./components/QuoteList";
import Aside from "./components/Aside";


export default function Quotes() {

    const [viewMenu, setViewMenu] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loadUSerQuotes = useUserQuotes()

    const userQuoteReducer = useSelector((state: IStore) => state.userQuote)
    const userQuoteResults = userQuoteReducer.results
    const userQuotePagination = userQuoteReducer.pagination


    useEffect(() => {
        (async () => {

            await loadUSerQuotes({
                limit: userQuotePagination.limit,
                offset: (Number(userQuotePagination.currentPage) - 1) * userQuotePagination.limit
            })
        })()
    }, [userQuotePagination.currentPage])

    return (
        <PageTemplate >
            <Container
                title="Minhas cotações"
                controll={
                    <>
                        <ButtonGroup
                            buttons={[
                                {
                                    buttonType: "button-icon-2",
                                    icon: < BiFilter />,
                                    onClick: () => setViewMenu(true)
                                },
                                {
                                    buttonType: "button-icon-2",
                                    icon: < BiPlus />,
                                    onClick: () => navigate("/cotacao/criar")
                                }
                            ]}
                        />
                    </>
                }
                footer={
                    <Pagination
                        pagination={{
                            ...userQuotePagination,
                            results: userQuoteResults
                        }}
                        goTo={(page: number) => dispatch(setPagination({ ...userQuotePagination, currentPage: page }))}
                    />
                }
                leftContent={<Aside />}
            >
                <>
                    <QuotesList />
                </>
            </Container >
        </PageTemplate >
    )
}

const MenuFilter = ({ view, setView }: { view: boolean, setView: any }) => {


    return (
        <Menu
            type="relative"
            options={[
                {
                    title: "Mais novo",
                    route: "any"
                }
            ]}
        />
    )
}




