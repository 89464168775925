import axios from "axios"

export default function useLoadStatusTask() {



    return async () => {

        try {
            const response = await axios.get("http://localhost:4000/api/task-status")

            return response.data

        } catch (error) {

            console.log("🚀 ~ file: useLoadStatusCategory.ts:16 ~ return ~ error:", error);
            
            throw error;
        }
    }
}