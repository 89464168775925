import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IStore } from "../../store/IStore"

export default function useGet() {

    const baseUrl = "http://localhost:4000/api"
    //@ts-ignore

    const token = useSelector((state: IStore) => state.account.user?.token)
    const localSession = window.sessionStorage.getItem("session")
    //@ts-ignore
    const session = JSON.parse(localSession)
    const userId = useSelector((state: IStore) => state.account.session?.userID)

    return async (url: string) => {
        const response = await fetch(`${baseUrl}${url}`, {
            method: "GET",
            headers: {
                'authorization': session.token,
                'Content-Type': 'application/json',
                'userID': `${userId}`,
                'token': `${token}`
            }
        })
        console.log("🚀 ~ file: useGet.ts:21 ~ return ~ response:", response);
        const responseData = await response.json();
        if(response.ok) {
            
            console.log("🚀 ~ file: useGet.ts:29 ~ return ~ responseData:", responseData);
            return responseData   
        }

        if(!responseData.ok) {
            console.log({responseData})
            throw Error(responseData.error.message)
        }

        return responseData
    }
}