import React, { SetStateAction } from "react";
import List from "../../../components/List";
import style from "../SellMiles.module.scss";


type Aside = {
    setScreen: React.Dispatch<React.SetStateAction<Screen>>
}

export default function Aside({ setScreen }: Aside) {


    const options = [
        {
            label: "Minhas ofertas",
            value: "offers",

        },
        {
            label: "Minhas vendas",
            value: "sells",
        }
    ]


    return (
        <List
            component={<ItemComponent setScreen={setScreen} />}
            array={options}
            dataKey={"item"}
        />
    )
};

type itemComponent = {
    item?: {
        label: string,
        value: Screen
    },
    setScreen: React.Dispatch<SetStateAction<Screen>>
}

const ItemComponent = ({ item, setScreen }: itemComponent) => {

    if (!item || item === undefined) return null

    return (
        <div
            className={style["item"]}
            onClick={() => setScreen(item.value)}
        >
            {item?.label}
        </div>
    )
}