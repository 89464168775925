import Calendar from "react-calendar";
import "./calendar.scss"
import 'react-calendar/dist/Calendar.css';
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { IStore } from "../../store/IStore";
import List from "../List";
import { Task } from "../../@types/Task/Task";


export default function MyCalendar({ tasks }: { tasks: Task[] }) {

    if (!tasks) return null


    return <Calendar
        //@ts-ignore
        tileContent={(args) => {

            const date = args.date
            const arr: Task[] = []

            for (const task of tasks) {

                const taskDate = task.startDate
                if (taskDate) {

                    const taskDateObject = new Date(taskDate)

                    const taskDateDay = taskDateObject.getUTCDate()
                    const taskDateMonth = taskDateObject.getUTCMonth()
                    const taskDateYear = taskDateObject.getUTCFullYear()

                    const dateDay = date.getUTCDate()
                    const dateMonth = date.getUTCMonth()
                    const dateYear = date.getUTCFullYear()

                    if (dateYear === taskDateYear && dateMonth === taskDateMonth && dateDay === taskDateDay) {
                        arr.push(task)
                    }
                }
            }
            return (
                <>
                    <List
                        component={<DayContentComponent />}
                        dataKey="task"
                        array={arr}
                        limit={3}
                    />
                    {
                        (arr.length - 3) > 0
                            ? <div className="day-content-component">
                                +{arr.length - 3}
                            </div>
                            : null
                    }
                </>
            )
        }}
    />
}

type PropsDayContentComponent = {
    task?: Task,
}

const DayContentComponent = ({ task }: PropsDayContentComponent) => {


    return <div className={"day-content-component"}>{task?.title.substring(0, 20)}</div>
}