import { createSlice } from "@reduxjs/toolkit"
import { Status, Task } from "../../@types/Task/Task"

type TaskView = {
    view: "board" | "calendar",
    modalCreateTask: boolean

}

type InitialState = {
    tasks: {
        tasks: Task[],
        filteredTasks: Task[] | null
        loadingTasks: boolean
        loadedTasks: boolean
        activeTask: Task | null
    }
    categories: {
        categoriesTasks: {
            id: string
            category_text: string,
            category_value: string,
            category_color: string,
            checked: boolean
        }[]
        loadingCategoriesTasks: boolean
        loadedCategoriesTasks: boolean
    }
    status: {
        statusTasks: Status[],
        loadingStatusTasks: false,
        loadedStatusTasks: false
    },

    taskView: TaskView
}

const initialState: InitialState = {
    tasks: {
        tasks: [],
        filteredTasks: null,
        loadingTasks: false,
        loadedTasks: false,
        activeTask: null
    },
    categories: {
        categoriesTasks: [],
        loadingCategoriesTasks: false,
        loadedCategoriesTasks: false
    },
    status: {
        statusTasks: [],
        loadingStatusTasks: false,
        loadedStatusTasks: false
    },

    taskView: {
        view: "board",
        modalCreateTask: false
    }
}

const appSlice = createSlice({
    name: "tasksReducer",
    initialState,
    reducers: {
        setTasks: (state, action) => {
            state.tasks.tasks = action.payload
        },
        setActiveTask: (state, action) => {
            state.tasks.activeTask = action.payload
        },
        setCategoriesTasks: (state, action) => {
            state.categories.categoriesTasks = action.payload
        },
        setLoading: (state, action) => {
            state.tasks.loadingTasks = action.payload
        },
        setLoaded: (state, action) => {
            state.tasks.loadedTasks = action.payload
        },
        setLoadingCategories: (state, action) => {
            state.categories.loadingCategoriesTasks = action.payload
        },
        setLoadedCategories: (state, action) => {
            state.categories.loadedCategoriesTasks = action.payload
        },
        setStatus: (state, action) => {
            state.status.statusTasks = action.payload
        },
        setLoadingStatus: (state, action) => {
            state.status.loadingStatusTasks = action.payload
        },
        setLoadedStatus: (state, action) => {
            state.status.loadedStatusTasks = action.payload
        },
        pushTask: (state, action) => {
            state.tasks.tasks.push(action.payload)
        },
        setFilteredTasks: (state, action) => {
            state.tasks.filteredTasks =action.payload
        },


        setTaskView: (state, action) => {
            state.taskView.view = action.payload
        },
        setModalCreateTask: (state, action) => {
            state.taskView.modalCreateTask = action.payload
        }
    }
})


export const {
    setTasks,
    setActiveTask,
    setLoaded,
    setLoading,
    setCategoriesTasks,
    setLoadedCategories,
    setLoadingCategories,
    setLoadedStatus,
    setLoadingStatus,
    setStatus,
    pushTask,
    setFilteredTasks,
    setTaskView,
    setModalCreateTask
} = appSlice.actions

export default appSlice.reducer

export interface ITasks extends InitialState { }

export interface ITasksView extends TaskView { }