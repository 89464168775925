import { useDispatch, useSelector } from "react-redux"
import useLoadUserQuotes from "./useLoadUserQuotes"
import { setData, setLoaded, setLoading, setPagination } from "../../store/userQuote"
import useFail from "../notices/useFail"
import { ReqParams } from "../../@types/ReqParams"
import { IStore } from "../../store/IStore"

export default function useUserQuotes() {

    const loadUserQuotes = useLoadUserQuotes()
    const dispatch = useDispatch()
    const fail = useFail()
    
    const quotesPagination = useSelector((state: IStore) => state.userQuote.pagination)

    return async ({ filters, limit, offset }: ReqParams) => {
        try {
            dispatch(setLoading(true))
            const response = await loadUserQuotes({ filters, limit, offset })

            const pagination = calculePagination(response.results)

            dispatch(setPagination({
                ...quotesPagination,
                pages: pagination.pages,
                offset: offset,
            }))


            dispatch(setData(response))
            dispatch(setLoaded(true))

        } catch (error: any) {
            fail(error.message)
            throw error
        } finally {
            dispatch(setLoading(false))

        }
    }
};


function calculePagination(results: number) {

    const limit = 20

    const pages = Math.ceil(results / limit)

    const offset = 0

    return {
        pages,
        offset
    }


}