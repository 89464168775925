import { HTMLAttributes, useEffect } from "react"
import Button, { ButtonProps } from "../button"
import style from "./ButtonGroup.module.scss"
import classNames from "classnames"

type ButtonGroup = {
    buttons?: ButtonProps[],
    direction?: "column" | "row",
    justifyContent?: "space-between"
    className?: string
} & HTMLAttributes<HTMLDivElement>

export default function ButtonGroup({
    buttons,
    direction,
    justifyContent,
    className
}: ButtonGroup) {

    useEffect(() => {
        if (className) {
            const btnGroup = document.getElementsByClassName(`${style["button-group"]}`)[0]

            btnGroup?.classList.add(className)
        }
    }, [])



    return (
        <div
            className={classNames({
                [style["button-group"]]: true,
                [`${className}`]: !!className
            })}
            data-direction={direction}
            data-justify-content={justifyContent}
        >
            {
                buttons?.map((button) => {
                    return (
                        <Button {...button} />
                    )
                })}
        </div>
    )
}