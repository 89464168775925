import { useDispatch } from "react-redux"
import { setLoadingStatus, setStatus } from "../../store/tasks/taskReducer";
import useLoadStatusTask from "./useLoadStatusTask";

export default function useStatusTask() {

    const dispatch = useDispatch();
    const loadStatus = useLoadStatusTask()


    return async () => {

        try {
            
            dispatch(setLoadingStatus(true))
            
            const response = await loadStatus()
            dispatch(setStatus(response.data))      
            
            dispatch(setLoadingStatus(false))
        } catch (error) {

            console.log("🚀 ~ file: useStatusCategory.ts:21 ~ return ~ error:", error);
            
            dispatch(setLoadingStatus(false))
        
            throw error
        
        }
    }
}