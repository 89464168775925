import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux'
import store from './store/store';
import './App.scss'

import {
  BrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AppRoutes } from './routes/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, defaultContext } from './authProvider';

function App() {

  return (
    <Provider store={store}>
      <AuthProvider>
        <AppRoutes />
        <ToastContainer
          className="toast"
        />
      </AuthProvider>
    </Provider>
  );
}

export default App;
