import List from "../../../components/List";

export default function HomeConversor() {

    return (
        <List
            array={data}
            dataKey="conversor"
            component={<ConversorItem />}
        />
    )
}

const ConversorItem = ({ conversor, onClick }: { conversor?: { title: string, description: string }, onClick?: any }) => {


    return (
        <div onClick={() => onClick(conversor)}>
            <h3>{conversor?.title}</h3>
            <p>{conversor?.description}</p>
        </div>
    )
}


const data = [
    {
        id: 1,
        title: "Conversor de Comprimento",
        description: "Converte entre unidades de comprimento, como metros, centímetros, polegadas, pés, milhas, quilômetros, etc."
    },
    {
        id: 2,
        title: "Conversor de Área",
        description: "Converte entre unidades de área, como metros quadrados, pés quadrados, acres, hectares, etc."
    },
    {
        id: 3,
        title: "Conversor de Volume",
        description: "Converte entre unidades de volume, como litros, galões, metros cúbicos, pés cúbicos, etc."
    },
    {
        id: 4,
        title: "Conversor de Massa",
        description: "Converte entre unidades de massa, como quilogramas, gramas, libras, onças, etc."
    },
    {
        id: 5,
        title: "Conversor de Temperatura",
        description: "Converte entre unidades de temperatura, como graus Celsius, graus Fahrenheit e graus Kelvin."
    },
    {
        id: 6,
        title: "Conversor de Velocidade",
        description: "Converte entre unidades de velocidade, como quilômetros por hora (km/h), milhas por hora (mph), metros por segundo (m/s), etc."
    },
    {
        id: 7,
        title: "Conversor de Tempo",
        description: "Converte entre unidades de tempo, como segundos, minutos, horas, dias, semanas, anos, etc."
    },
    {
        id: 8,
        title: "Conversor de Pressão",
        description: "Converte entre unidades de pressão, como pascals (Pa), bares, atmosferas, libras por polegada quadrada (psi), etc."
    },
    {
        id: 9,
        title: "Conversor de Energia",
        description: "Converte entre unidades de energia, como joules (J), calorias (cal), quilowatts-hora (kWh), etc."
    },
    {
        id: 10,
        title: "Conversor de Velocidade Angular",
        description: "Converte entre unidades de velocidade angular, como rotações por minuto (RPM), radianos por segundo (rad/s), graus por segundo, etc."
    },
    {
        id: 11,
        title: "Conversor de Moeda",
        description: "Converte entre diferentes moedas internacionais, aplicando as taxas de câmbio atuais."
    },
    {
        id: 12,
        title: "Conversor de Densidade",
        description: "Converte entre unidades de densidade, como quilogramas por metro cúbico (kg/m³), gramas por centímetro cúbico (g/cm³), libras por pé cúbico (lb/ft³), etc."
    },
    {
        id: 13,
        title: "Conversor de Força",
        description: "Converte entre unidades de força, como newtons (N), libras (lb), quilogramas-força (kgf), etc."
    },
    {
        id: 14,
        title: "Conversor de Potência",
        description: "Converte entre unidades de potência, como watts (W), cavalos de potência (hp), quilowatts (kW), etc."
    },
    {
        id: 15,
        title: "Conversor de Vazão",
        description: "Converte entre unidades de vazão, como litros por segundo (L/s), metros cúbicos por hora (m³/h), galões por minuto (GPM), etc."
    }
]