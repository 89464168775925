import React from "react";

const debounce = (func: any, wait = 200) => {
    let timeout: any; 
    function executedFunction(...args: any) { 
        const later = () => { 
            clearTimeout(timeout); 
            func(...args); 
        };
        clearTimeout(timeout); 
        
        timeout = setTimeout(later, wait); 
    };
    executedFunction.cancel = function() { 
        clearTimeout(timeout); 
    };
    return executedFunction;
};


function useDebounce(callback: any, delay = 1000, deps = []) {
    
    const debouncedCallback = React.useCallback(debounce(callback, delay), [delay, ...deps]);
    
    React.useEffect(() => {
        return () => {
            debouncedCallback.cancel(); 
        }
    }, [delay, ...deps]);
    
    return debouncedCallback;
};

export default useDebounce;