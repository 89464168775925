import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    view: boolean
}

const initialState: InitialState = {
    view: false
}

const appSlice = createSlice({
    name: "expandContainerReducer",
    initialState,
    reducers: {
        setExpandContainer: (state, action) => {
            state.view = action.payload
        }
    }
})


export const  {
    setExpandContainer
} = appSlice.actions

export default appSlice.reducer

export interface IExpandContainer extends InitialState { }