import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../store/IStore"
import ExpandContainer from "../../../components/expandContainer/expandContainer"
import style from "./expandTask.module.scss"
import Chip from "../../../components/chip/chip"
import List from "../../../components/List"
import Form from "../../../components/form"
import { z } from "zod"
import EmojiPicker from "emoji-picker-react"
import { Emoji } from 'emoji-picker-react';
import Button from "../../../components/button"
import { BiTrash } from "react-icons/bi"
import PopupExample from "../../../components/popup/popup"
import useFail from "../../../hooks/notices/useFail"
import useDelete from "../../../hooks/http/useDelete"
import useSuccess from "../../../hooks/notices/useSuccess"
import { setExpandContainer } from "../../../store/expandContainer"
import { setActiveTask } from "../../../store/tasks/taskReducer"
import Input from "react-select/dist/declarations/src/components/Input"
import { useEffect, useState } from "react"
import { Task } from "../../../@types/Task/Task"
import { MdShortText } from "react-icons/md"
import { TbCategory } from "react-icons/tb"
import { BsAt } from "react-icons/bs"
import { CiCalendarDate } from "react-icons/ci"

export default function ExpandTask() {
    const activeTask = useSelector((state: IStore) => state.tasks.tasks.activeTask)
    const categoriesTask = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)
    const statusTask = useSelector((state: IStore) => state.tasks.status.statusTasks)
    const activeCategory = categoriesTask.find(c => c.id === activeTask?.category)
    const activeStatus = statusTask.find(c => c.id === activeTask?.status)

    const deletar = useDelete();
    const fail = useFail();
    const success = useSuccess();
    const dispatch = useDispatch();
    const schemaComentary = z.object({
        comentary: z.string(),
        author_id: z.string()
    })

    const handleDelete = async () => {

        const schema = z.object({
            task_id: z.string(),
        }).parse({
            task_id: activeTask?.id
        })

        try {
            const response = await deletar("/task", schema)
            dispatch(setExpandContainer(false))
            dispatch(setActiveTask(null))
            success("Tarefa deletada!")

            return
        } catch (error: any) {
            fail(error.message)
        }
        return
    }

    const [form, setForm] = useState<Task | null>(activeTask)

    useEffect(() => {
        setForm(activeTask)
        console.log(schema)
    }, [activeTask])

    const schema = z.object({
        title: z.optional(z.string()),
        description: z.optional(z.string()),
        startDate: z.optional(z.string()),
        endDate: z.optional(z.string()),
        status: z.optional(z.number()),
        category: z.optional(z.number())
    }).or(z.null())

    const categoriesReducer = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)
    const categories = categoriesReducer.map(c => { return { label: c.category_text, value: c.category_value } })

    const statusReducer = useSelector((state: IStore) => state.tasks.status.statusTasks)
    const status = statusReducer.map(s => {
        return {
            label: s.status_text,
            value: s.id,
        }
    })

    return (
        <ExpandContainer>
            <section className={style["expand-task-control"]}>
                <PopupExample
                    popupMessage="Tem certeza que deseja deletar esta tarefa?"
                    button={{
                        icon: <BiTrash />,
                        buttonType: "button-icon-2"
                    }}
                    onConfirm={() => handleDelete()}
                    confirmText={"Excluir"}
                />
            </section>
            <section className={style["expand-task-form"]}>
                <Form
                    schema={schema}
                    //title={form.title}
                    controlType="update_on_change"
                    inputs={[
                        {
                            label: "Título",
                            name: "title",
                            type: "text",
                            icon: <MdShortText />,
                            placeholder: "Título",
                            direction: "row",
                            defaultValue: activeTask?.title

                        },
                        {
                            label: "Descrição",
                            name: "description",
                            type: "text",
                            icon: <MdShortText />,
                            placeholder: "Descrição",
                            direction: "row",
                            defaultValue: activeTask?.description
                        },
                        {
                            label: "Início:",
                            name: "startDate",
                            type: "date",
                            placeholder: "__/__/____",
                            icon: <CiCalendarDate />,
                            direction: "row",
                            defaultValue: activeTask?.startDate

                        },
                        {
                            label: "Fim:",
                            name: "endDate",
                            type: "date",
                            placeholder: "__/__/____",
                            icon: <CiCalendarDate />,
                            direction: "row",
                            defaultValue: activeTask?.endDate
                        },
                        {
                            label: "Status",
                            name: "status",
                            type: "select",
                            icon: <BsAt />,
                            defaultValue: activeTask?.status,
                            direction: "row"
                        },
                        {
                            label: "Categoria",
                            name: "category",
                            type: "select",
                            options: categories,
                            icon: <TbCategory />,
                            direction: "row",
                            defaultValue: activeTask?.category
                        },
                    ]}
                />
            </section>
            {/* <section className={style["expand-task"]}>

                <span className={style["expand-task__label--task"]}>Tarefa:</span>
                <div className={style["expand-task__title"]}>
                    <h3 >{activeTask?.title}</h3>
                </div>


                <span className={style["expand-task__label--description"]}>Descrição:</span>
                <div className={style["expand-task__description"]}>
                    <p>{activeTask?.description}</p>
                </div>

                <span className={style[`expand-task__label--start`]}>Início:</span>
                <div className={style[`expand-task__start`]}>
                    <span>{activeTask?.startDate}</span>
                </div>


                <span className={style[`expand-task__label--end`]}>Fim:</span>
                <div className={style["expand-task__end"]}>
                    <span >{activeTask?.endDate}</span>
                </div>

                <span className={style[`expand-task__label--status`]}>Status:</span>
                <div className={style["expand-task__status"]}>
                    <span>{activeStatus?.status_text}</span>
                </div>

                <span className={style[`expand-task__label--category`]}>Categoria:</span>
                <div className={style["expand-task__category"]}>
                    <Chip
                        message={activeCategory?.category_text}
                        color={activeCategory?.category_color}
                    />
                </div>

            </section> */}
            <section className={style["expand-task-comentary"]}>

                <p className={style["head"]}>Comentários</p>
                <List
                    className={style["expand-task-comentary__list"]}
                    dataKey="item"
                    array={[
                        {
                            comentary: "algum comentario",
                            author: {
                                author_name: "Fernando"
                            }
                        },
                        {
                            comentary: "Eu acho que tá ficando bom.",
                            author: {
                                author_name: "Luana"
                            }
                        }
                    ]}
                    component={<ComentComponent />}
                />
                <Form
                    inputs={[
                        {
                            placeholder: "Adicionar comentário",
                            name: "comentary",
                            type: "text"
                        }
                    ]}
                    schema={schemaComentary}
                    endpoint=""
                    controlType="single_send"
                />
            </section>
        </ExpandContainer>
    )
}

type Coment = {
    item?: {
        comentary: string
        author: {
            author_name: string,
            author_img: string
        }

    }
}

const ComentComponent = ({ item }: Coment) => {

    return (
        <div className={style["coment-box"]}>
            <div className={style["coment-box__img"]}>
                {item?.author.author_img
                    ? <img src="" alt="" />
                    : <span>{item?.author.author_name[0]}</span>
                }
            </div>
            <p className={style["coment-box__txt"]}>{item?.comentary}</p>
            <div className={style["coment-box__reactions"]}>
                <Emoji unified="1f423" size={20} />
                <div className={style["coment-box__reactions-count"]}>3</div>
            </div>
        </div>
    )
}