import PageTemplate from "../../components/pageTemplate/intex";
import style from "./Task.module.scss"
import { useEffect, useState } from "react";
import Container from "../../components/container";
import axios from "axios";
import CreateTask from "./CreateTask";
import Category from "./category";
import { useDispatch, useSelector } from "react-redux";
import { IStore, IView } from "../../store/IStore";
import useTasks from "../../hooks/tasks/useTasks";
import { setActiveTask } from "../../store/tasks/taskReducer";
import useStatusTask from "../../hooks/status/useStatusTask";
import UpdateTask from "./modalTask";
import { Task, Task as TaskType } from "../../@types/Task/Task";
import CalendarTask from "./calendarTask";
import ExpandTask from "./expandTask";
import { Nav } from "../../components/navbar";
import ModalStatus from "./modalStatus";
import BoardTask from "./boardTask/boardTask";
import { ControlTask } from "./controlTask/controlTask";

export default function TaskView() {
	const [view, setView] = useState(false)
	const [loadingUpdate, setLoadingUpdate] = useState(false)
	const [createTask, setCreateTask] = useState(false)
	const [viewModalStatus, setViewModalStatus] = useState(false)

	const tasksReducer = useSelector((state: IStore) => state.tasks.tasks)
	const tasks = tasksReducer.tasks
	const activerTask = tasksReducer.activeTask
	const loadedTasks = tasksReducer.loadedTasks
	const loadingTasks = tasksReducer.loadingTasks

	const taskStatusReducer = useSelector((state: IStore) => state.tasks.status)
	const statusTask = taskStatusReducer.statusTasks
	const loadingStatusTask = taskStatusReducer.loadingStatusTasks
	const loadedStatusTask = taskStatusReducer.loadedStatusTasks

	const taskView = useSelector((state: IStore) => state.tasks.taskView)

	const [form, setForm] = useState<TaskType>()

	console.log("🚀 ~ file: index.tsx:45 ~ TaskView ~ taskView:", taskView);


	const loadTasks = useTasks();
	const loadStatusTask = useStatusTask();
	const dispatch = useDispatch()


	useEffect(() => {
		(async () => { await loadTasks({}) })()
	}, [])

	useEffect(() => { (async () => { if (!loadedStatusTask) { await loadStatusTask() } })() }, [])

	useEffect(() => {
		if (!activerTask) return
		setForm(activerTask)
	}, [activerTask?.id])

	const handleUpdate = async (e: any) => {
		e.preventDefault()
		try {
			const data = {
				id: form?.id,
				title: form?.title,
				description: form?.description,
				startDate: form?.startDate,
				endDate: form?.endDate,
				status: Number(form?.status),
				category: Number(form?.category)
			}
			setLoadingUpdate(true)
			const response = await axios.put("http://localhost:4000/api/task", data)
			return response
		} catch (error) {
			console.log("🚀 ~ file: index.tsx:160 ~ handleUpdate ~ error:", error);
		} finally {

			setLoadingUpdate(false)
			setView(false)
			dispatch(setActiveTask(null))

			await loadTasks({})
		}
	}

	return (
		<PageTemplate>
			<Container
				title="Tarefas"
				leftContent={<Category />}
				controll={<ControlTask setCreateTask={setCreateTask} />}
			>
				<div style={{ display: "flex" }}>
					{
						taskView.view === "board" &&
						<BoardTask />
					}
					{
						taskView.view === "calendar" &&
						<CalendarTask tasks={tasks} />
					}

					<CreateTask
						view={taskView.modalCreateTask}
						setView={setCreateTask}
					/>
					<ModalStatus
						view={viewModalStatus}
						setView={setViewModalStatus}
					/>
					<UpdateTask
						form={form}
						setForm={setForm}
						view={view}
						setView={setView}
						activerTask={activerTask}
						handleUpdate={handleUpdate}
					/>
					<ExpandTask />
				</div>
			</Container>
		</PageTemplate>
	)
}
