import { ReactElement, ReactNode } from "react";
import style from "./PageTemplate.module.scss"
import Navbar from "../navbar";

type Props = {
    children: ReactNode
}

export default function PageTemplate({ children }: Props) {


    return (
        <section>

            <div className={style["page-template"]}>
                <Navbar />
                <div className={style["children"]}>
                    {children}

                </div>
            </div>
        </section>
    )
}