import { createSlice } from "@reduxjs/toolkit";

export type Nav = {
    id: string | number
    label: string
}

export type NavOptions = Nav[]

type InitialState = {
    options: NavOptions | null
    activeOption: Nav | null
}

const initialState: InitialState = {
    activeOption: null,
    options: null
}

const appSlice = createSlice({
    initialState,
    name: "navReducer",
    reducers: {
        setOptions: (state, action) => {
            state.options = action.payload
        },
        setActiveOption: (state, action) => {
            state.activeOption = action.payload
        }

    }
})

export const { 
    setActiveOption,
    setOptions
} = appSlice.actions

export interface INav extends InitialState {}

export default appSlice.reducer