import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    session: {
        username:string,
        email:string,
        token: string,
        userID: string
    } | null
    isLoggedIn: boolean,
    user: {
        userID: string,
        name: string,
        email: string
    } | null;
}

const initialState: InitialState = {
    isLoggedIn: false,
    user: null,
    session: null
}

const appSlice = createSlice({
    name: "accountReducer",
    initialState,
    reducers: {
        isLoggedIn: (state, action) =>{
            state.isLoggedIn = action.payload
        },
        user: (state, action) => {
            state.user = action.payload
        },
        setSession: (state, action) => {
            state.session = action.payload
        }
    }
}) 

export const {
    isLoggedIn,
    user,
    setSession
} = appSlice.actions

export interface IAccount extends InitialState { }

export default appSlice.reducer