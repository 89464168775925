import { SubmitHandler, useForm } from "react-hook-form"
import Input, { InputProps } from "../input/Input"
import { ZodError, z } from "zod"
import ButtonGroup from "../buttonGroup/ButtonGroup"
import { zodResolver } from '@hookform/resolvers/zod';
import style from "./Form.module.scss"
import usePost from "../../hooks/http/usePost";
import useFail from "../../hooks/notices/useFail";
import Button from "../button";
import { BiSend } from "react-icons/bi";
import { useEffect } from "react";
import useDebounce from "../../hooks/functions/useDebounce";
import useSuccess from "../../hooks/notices/useSuccess";

type FormProps = {
    schema: any
    endpoint?: string
    title?: string
    inputs: InputProps[]
    controlType?: "single_send" | "update_on_change"
    children?: any
    successMessage?: string
    submitMessage?: string
}

export default function Form({
    schema,
    endpoint,
    inputs,
    title,
    controlType,
    children,
    successMessage,
    submitMessage
}: FormProps) {

    type FormSchema = z.infer<typeof schema>
    const post = usePost()
    const fail = useFail()
    const success = useSuccess();
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors, isSubmitting, isLoading, isValid, isDirty, dirtyFields }
    } = useForm<FormSchema>({
        resolver: zodResolver(schema),
        mode: "all",
        reValidateMode: "onChange"
    });

    console.log({ isSubmitting, isLoading, isValid, isDirty, dirtyFields })

    const onSubmit = async (data: FormSchema) => {
        try {
            const result = schema.parse(data)

            const response = await post(`/${endpoint}`, result)
            console.log(result)

            successMessage && success(successMessage)

            return response
        } catch (error: any) {
            if (error instanceof ZodError)
                console.error(error.flatten())
            console.log(error)
            fail(error.message)
        }
    }
    const debounceOnSubmit = useDebounce(onSubmit)

    useEffect(() => {
        if (controlType === "update_on_change") {
            console.log('updating')
            handleSubmit(debounceOnSubmit)
        }
    }, [schema])

    console.log(watch("example")) // watch input value by passing the name of it

    //const inputs: InputProps[] = []



    return (
        <form
            className={style["form"]}
            onSubmit={() => handleSubmit(onSubmit)}
            data-singleInput={controlType && inputs.length === 1}
        >
            {!!title && <h3 className={style["form__title"]} >{title}</h3>}
            {children && children}

            <div className={style["form__input-group"]}>
                {inputs.map((input) => {
                    const name = input.name

                    return (
                        <Input
                            {...input}
                            {...register(name)}
                            error={!!errors[name]?.message}
                            errorMessage={errors[name]?.message as string}
                        />
                    )
                })}
            </div>

            <Control
                type={controlType}
                handleSubmit={handleSubmit(onSubmit)}
                submitMessage={submitMessage}
            />

        </form>
    )
}

type ControlForm = {
    type?: "single_send" | "update_on_change",
    handleSubmit: any,
    submitMessage?: string
}


const Control = ({ type, handleSubmit, submitMessage }: ControlForm) => {

    if (type === "single_send") {
        return (
            <Button
                type="button"
                buttonType="button-icon-2"
                icon={<BiSend />}
                onClick={handleSubmit}
            />
        )
    }
    if (type === "update_on_change") {

        return null
    }

    return (
        <ButtonGroup
            justifyContent="space-between"
            direction="column"
            buttons={[
                {
                    buttonType: "secondary",
                    children: "Cancelar",
                    type: "button"
                },
                {
                    buttonType: "primary",
                    children: submitMessage ? submitMessage : "Criar Status",
                    onClick: handleSubmit,
                    type: "button"
                }
            ]}
        >

        </ButtonGroup>
    )
}