import { Task } from "../../../@types/Task/Task";
import MyCalendar from "../../../components/calendar";
import style from "./CalendarTask.module.scss"

export default function CalendarTask({ tasks }: { tasks: Task[] }) {

    return (
        <div className={style["task-month"]}>
            <MyCalendar tasks={tasks} />
        </div>
    )
}
