import useFail from "../notices/useFail";
import useSuccess from "../notices/useSuccess";

export default function usePost() {

    const baseUrl = "http://localhost:4000/api"
    const userID = '1'
    return async (endPoint: string, data: any) => {

        const response = await fetch(`${baseUrl}${endPoint}`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 
                "Content-type": "application/json; charset=UTF-8" ,
                'userID': userID
            }
            
        })
        
        const responseData = await response.json();
        console.log({response})
        if(!response.ok) {
            console.log({ responseData })
            throw Error(responseData.error.message)
            
        }

        return responseData
    }
}