import { CiCalendarDate } from "react-icons/ci"
import Modal from "../../../components/modal"
import { MdShortText } from "react-icons/md"
import { BsAt } from "react-icons/bs"
import { TbCategory } from "react-icons/tb"
import Button from "../../../components/button"
import style from './ModalTask.module.scss'
import { useSelector } from "react-redux"
import { IStore } from "../../../store/IStore"
import { Task } from "../../../@types/Task/Task"
import Form from "../../../components/form";
import { z } from "zod"

type PropsFormTask = {
    form: Task | undefined,
    setForm: any,
    view: any,
    setView: any,
    activerTask: any,
    handleUpdate: any
}

const schema = z.object({
    title: z.string(),
    description: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    status: z.string(),
    category: z.string()
})

export default function ModalTask({
    form,
    setForm,
    view,
    setView,
    activerTask,
    handleUpdate
}: PropsFormTask) {

    const categoriesReducer = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)
    const categories = categoriesReducer.map(c => { return { label: c.category_text, value: c.category_value } })

    const statusReducer = useSelector((state: IStore) => state.tasks.status.statusTasks)
    const status = statusReducer.map(s => {
        return {
            label: s.status_text,
            value: s.id,
        }
    })

    if (!form) return null

    return (

        <Modal
            view={view}
            setView={setView}
        >
            <div className={style["active-task"]}>
                <Form
                    schema={schema}
                    title={form.title}
                    inputs={[
                        {
                            label: "Descrição",
                            name: "title",
                            type: "text",
                            icon: <MdShortText />
                        },
                        {
                            label: "Data de início:",
                            name: "startDate",
                            type: "date",
                            icon: <CiCalendarDate />
                        },
                        {
                            label: "Data final:",
                            name: "endDate",
                            type: "date",
                            icon: <CiCalendarDate />
                        },
                        {
                            label: "Status",
                            name: "status",
                            type: "select",
                            options: status,
                            icon: <BsAt />
                        },
                        {
                            label: "Categoria",
                            name: "category",
                            type: "select",
                            options: categories,
                            icon: <TbCategory />
                        },
                    ]}
                />
            </div>
        </Modal>

    )
}