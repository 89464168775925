import classNames from "classnames"
import quoteStyle from "../Component.module.scss"
import { useNavigate } from "react-router-dom"
import Button from "../../../components/button"
import { userQuote } from "../../../@types/Task/UserQuote"
import { FaUser } from "react-icons/fa6";
import { LuBaby } from "react-icons/lu";
import { GiAirplaneArrival, GiAirplaneDeparture } from "react-icons/gi";
import { FiEye } from "react-icons/fi";
import { MdMenu } from "react-icons/md";
type componentProps = { item?: userQuote }
export default function QuoteItem({ item }: componentProps) {


    const originDate = trattedDate(item?.ticket_origin_date)
    const destinyDate = trattedDate(item?.ticket_destiny_date)

    function trattedDate(param?: string) {

        if (!param) return {
            day: "(error)",
            month: "(error)",
            year: "(error)"
        }

        var dataObj = new Date(param);
        var data = dataObj.toISOString().split('T')[0];

        const year = data.split("-")[0]
        const month = data.split("-")[1]
        const day = data.split("-")[2]

        return {
            day,
            month,
            year
        }
    }

    const navigate = useNavigate()

    const navigateToItem = () => {
        navigate(`/cotacao/${item?.id}`)
    }

    return (
        <>
            <div className={quoteStyle["container"]}>
                <div className={quoteStyle["label-origin"]}>Status</div>
                <div className={quoteStyle["origin"]}>
                    <div className={quoteStyle["origin-name"]}>{item?.ticket_origin} X {item?.ticket_destiny}</div>
                </div>

                <div className={quoteStyle["details"]}>
                    <div className={quoteStyle["origin-date"]}> <GiAirplaneDeparture /> {`${originDate.day}/${originDate.month}`}</div>
                    {!item?.one_way && <div className={quoteStyle["destiny-date"]}><GiAirplaneArrival /> {`${destinyDate.day}/${destinyDate.month}`}</div>}
                    <div className={quoteStyle["adults"]}><FaUser /> {item?.adults}</div>
                    <div className={quoteStyle["childrens"]}><LuBaby />{item?.childrens}</div>
                </div>
                <div className={quoteStyle["button"]}>


                    <Button
                        buttonType="button-icon-2"
                        onClick={() => navigateToItem()}
                        icon={<MdMenu />}
                    >
                    </Button>
                </div>
            </div>
        </>

    )
}