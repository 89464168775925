import style from "./Overlay.module.scss"

export default function Overlay({ view, onClick, id }: { view: boolean | "initial_state", onClick?: Function, id?: string }) {


    return (
        <div
            data-view={view}
            className={style[`overlay`]}
            onClick={onClick ? () => onClick() : undefined}
            id={id}
        >
        </div>
    )
}