import List from "../../../components/List";
import style from "../Component.module.scss";

export default function Aside() {


    const arr: data[] = [
        {
            cia: "Gol",
            value: 1299.35
        },
        {
            cia: "Gol",
            value: 1299.35
        },
        {
            cia: "Gol",
            value: 1299.35
        },
        {
            cia: "Gol",
            value: 1299.35
        },
    ]

    return (


        <div className={style["aside"]}>


            <h3>Valores de mercado</h3>

            <References />

            <p>Preços no balcão de milhas</p>

            <List
                component={<Component />}
                dataKey="item"
                array={arr}
            />


        </div>
    )
};

type data = {

    cia: "Gol" | "Azul" | "Latam"
    value: number

}

type Comp = {
    item?: data
}
const Component = ({ item }: Comp) => {
    return (

        <div className={style["quote"]}>
            <div className={style["quote__img"]}>
                <img src="" alt="" />
            </div>
            <div className={style["quote__operator"]}>
                {item?.cia}
            </div>
            <div className={style["quote__value"]}>
                ${item?.value}
            </div>
        </div>

    )
}



const References = () => {

    const refs: RefBox["referenceBox"][] = [
        {
            cia: "Gol",
            min_value: 100,
            max_value: 250
        },
        {
            cia: "Latam",
            min_value: 100,
            max_value: 250
        },
        {
            cia: "Azul",
            min_value: 100,
            max_value: 250
        },
    ]

    return (
        <div className={style["references"]}>
            <p>Preços de Referência</p>
            <div className={style["references__boxes"]}>

                {refs.map((r) => {

                    return (<ReferenceBox referenceBox={r} />)
                })}
            </div>
        </div>
    )
}

type RefBox = {
    referenceBox: {
        cia: string
        min_value: number
        max_value: number
    }
}
const ReferenceBox = ({ referenceBox }: RefBox) => {
    return (

        <div className={style["references__box"]}>
            <div className={style["references__box--cia"]}>{referenceBox.cia}</div>
            <div className={style["references__box--min_value"]}><span>Mín:</span> {referenceBox.min_value}</div>
            <div className={style["references__box--max_value"]}><span>Máx:</span> {referenceBox.max_value}</div>
        </div>
    )
}