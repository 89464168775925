import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../store/IStore"
import QuoteItem from "./QuoteItem"
import List from "../../../components/List"

export default function QuotesList() {

    const userQuoteReducer = useSelector((state: IStore) => state.userQuote)
    const userQuoteData = userQuoteReducer.data
    const userQuoteLoading = userQuoteReducer.loading

    return (
        userQuoteData?.length > 0
            ? <>
                <List
                    loading={userQuoteLoading}
                    array={userQuoteData}
                    component={<QuoteItem />}
                    dataKey="item"
                />
            </>
            : <>No data</>
    )

};
