import { useState } from "react";
import Modal from "../../../components/modal";
import { z } from "zod";
import Form from "../../../components/form";

const schema = z.object({
    status_text: z.string().min(3, "Digite pelo menos tr3s caracteres"),
    status_color: z.string()
})

export default function ModalStatus({
    view,
    setView
}: {
    view: boolean,
    setView: any
}) {
    return (
        <Modal
            view={view}
            setView={setView}
        >
            <Form
                title="Criar novo status"
                schema={schema}
                endpoint="task-status"
                inputs={[
                    {
                        label: "Nome",
                        name: "status_text",
                        placeholder: "sugestivo",
                        type: "text"
                    },
                    {
                        label: "Cor",
                        name: "status_color",
                        //@ts-ignore
                        type: "color"
                    }
                ]}
            />
        </Modal>
    )
}