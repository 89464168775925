import List from '../../components/List';
import PageTemplate from '../../components/pageTemplate/intex';
import TextArea from '../../components/Typography/TextArea';
import { data } from './data';
export default function Home() {

    return (
        <PageTemplate>
            <section>
                <TextArea
                    title="Desafio 10"
                    text='Criei este desafio com o objetivo de criar aplicações simples que impactam meu dia-a-dia.'
                />
                <List
                    array={data}
                    component={<Component />}
                    dataKey={"item"}
                />
            </section>
        </PageTemplate>

    )
}

type Props = {
    item?: {
        title?: string
        description?: string,
    },
}

const Component = ({ item }: Props) => {
    return <TextArea
        title={item?.title}
        text={item?.description}
        textSize='small'
    />
}