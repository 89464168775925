import style from "./Overlay.module.scss";

type Props = {
    hidden?: boolean,
    setView: any
}

export default function Overlay({ hidden, setView }: Props) {


    return (
        <div
            data-hidden={hidden}
            className={style["overlay"]}
            onClick={() => setView(false)}
        ></div>
    )
}