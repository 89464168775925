import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IStore } from "../../store/IStore"

export default function useDelete() {

    const baseUrl = "http://localhost:4000/api"
    //@ts-ignore

    const token = useSelector((state: IStore) => state.account.user?.token)
    const localSession = window.sessionStorage.getItem("session")
    //@ts-ignore
    const session = JSON.parse(localSession)
    const userId = useSelector((state: IStore) => state.account.session?.userID)

    return async (url: string, data: any) => {

        const response = await fetch(`${baseUrl}${url}`, {
            method: "DELETE",
            body: JSON.stringify(data),

            
            headers: {
                'authorization': session.token,
                'Content-Type': 'application/json',
                'userID': `${userId}`,
                'token': `${token}`
            }
        })
        console.log({response});
        
        const responseData = await response.json();

        
        
        if(response.ok) {
            console.log({responseData});
            
            return responseData   
        }

        if(!responseData.ok) {
            console.log({responseData})
            throw Error(responseData.error.message)
        }

        return responseData
    }
}