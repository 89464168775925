import style from "./loadingPage.module.scss"

export default function LoadingPage() {



    return (
        <div className={style["loading-page"]}>
            <span>Carregando</span>
        </div>
    )
}