import style from "./chip.module.scss";



export default function Chip({ message, color, size }: { message?: string, color?: string, size?: "sm" | "lg" | "xlg" }) {

    return (
        <p
            className={style["chip"]}
            style={{ backgroundColor: color }}
            data-size={size}
        >{message}</p>
    )
}