import useSuccess from "../notices/useSuccess";

export default function usePut() {

    const baseUrl = "http://localhost:4000/api"

    const success = useSuccess()
    return async (endPoint: string, data: any) => {

        try {
            const response = await fetch(`${baseUrl}${endPoint}`, {
                method: "PUT",

                
                
                
                body: JSON.stringify(data),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })
            
            console.log("🚀 ~ file: usePut.ts:14 ~ return ~ response:", response);

            if (!response.ok) throw new Error("Erro na solicitação");

            const responseData = await response.json();

            console.log("🚀 ~ file: usePost.ts:20 ~ return ~ responseData:", responseData);

            
            return responseData
        } catch (error: any) {

            success(error.message)
            console.log({error: error.message});

            return error
            
        }   
    }
}