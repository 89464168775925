import Text from "../Text"
import style from "./TextArea.module.scss"

type Props = {
    title?: string
    text?: string
    textSize?: SizeProps
}

export default function TextArea({ title, text, textSize }: Props) {

    return (
        <div>
            {title && <h1 className={style["heading"]}>{title}</h1>}
            {text && <Text size={textSize}>{text}</Text>}
        </div>
    )
}