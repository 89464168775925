import { useEffect, useState } from 'react'
import data from './menu.json'
import style from './Menu.module.scss'
import { TiThMenuOutline } from 'react-icons/ti'
import { click } from '@testing-library/user-event/dist/click'
import { wait } from '@testing-library/user-event/dist/utils'
import { AiOutlineMenu } from 'react-icons/ai'
import Overlay from '../overlay'


type Props = {
    options?: {
        title: string,
        route: string | undefined
    }[]
    type?: "aside" | "relative"
}

export default function Menu({ type, options }: Props) {

    const [view, setView] = useState<boolean | "initial_state">("initial_state")

    const button = document.querySelector(`.${style["menu__button"]}`)

    button?.addEventListener("click", async () => {
        setView(true)
    })
    useEffect(() => {
        setView("initial_state")
    }, [])

    return (
        <>
            <div className={style["menu"]}>
                <button
                    title='button'
                    className={style["menu__button"]}
                    onClick={() => setView(true)}
                    type='button'
                >
                    <AiOutlineMenu />
                </button>
                <div
                    className={style[`menu__list-wrapper`]}
                    data-type={type}
                    data-view={view}
                >
                    <ul
                        className={style[`menu__list`]}
                    >
                        {data.map((item: any) => {
                            return (
                                <li
                                    className={style[`menu__item`]}

                                >
                                    <a href={item.route}>
                                        {item.title}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <Overlay
                view={view}
                id={style["overlay"]}
                onClick={() => setView(false)}
            />
            {/* <div
                data-view={view}
                className={style[`overlay`]}
            >
            </div> */}

        </>
    )
}