import React, { useEffect, useRef, useState } from 'react';
import Button, { ButtonProps } from '../button';
import style from "./popup.module.scss";
import ButtonGroup from '../buttonGroup/ButtonGroup';

type PropsPopup = {
    popupMessage: string,
    button: ButtonProps,
    onConfirm: any,
    confirmText?: string
}

const PopupExample = ({ popupMessage, button, onConfirm, confirmText }: PropsPopup) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isDivClicked, setDivClicked] = useState(false);
    const divRef = useRef(null);
    console.log("🚀 ~ file: popup.tsx:8 ~ PopupExample ~ isPopupOpen:", isPopupOpen);


    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    const popup = document.getElementById(style["popup"])

    popup?.addEventListener("click", (e) => {
        console.log("click")
        if (isPopupOpen && e.target !== popup) {
            return setPopupOpen(false)
        }

    })

    const handleClickOutside = (event: any) => {
        //@ts-ignore
        if (divRef.current && !divRef.current.contains(event.target)) {
            // O clique ocorreu fora da div
            setDivClicked(false);
            setPopupOpen(false);
        }
    };
    const handleDivClick = () => {
        // Atualiza o estado quando a div é clicada
        setDivClicked(true);
    };

    useEffect(() => {
        // Adiciona um event listener quando o componente é montado
        document.addEventListener('click', handleClickOutside);

        // Remove o event listener quando o componente é desmontado
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // A lista de dependências vazia garante que o event listener é adicionado apenas uma vez


    return (
        <div
            ref={divRef}
            className={style['popup']}
            onClick={handleDivClick}
        >
            <Button
                {...button}
                onClick={() => {
                    setPopupOpen(!isPopupOpen)
                }}
            />

            {isPopupOpen && (
                <div className={style["popup-overlay"]}>
                    <div className={style["popup-content"]}>
                        <p>{popupMessage}</p>
                        <ButtonGroup
                            justifyContent='space-between'
                            buttons={[
                                {
                                    buttonType: 'secondary',
                                    onClick: () => setPopupOpen(false),
                                    children: "Cancelar"
                                },
                                {
                                    buttonType: 'secondary',
                                    onClick: onConfirm,
                                    children: confirmText
                                },
                            ]}
                        >

                            <Button

                            >
                                Cancelar
                            </Button>
                            <Button
                                buttonType='secondary'
                                onClick={onConfirm}
                            >{confirmText}</Button>
                        </ButtonGroup>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default PopupExample;
