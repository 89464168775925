import {
    Route,
    RouteProps,
    Navigate,
    Routes,
    BrowserRouter,
} from "react-router-dom";
import Home from "../views/home";
import Task from "../views/task";
import Login from "../views/login";
import ConversorView from "../views/conversor";
import CreateAccount from "../views/login/criarConta";
import { useAuth } from "../authProvider";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isLoggedIn, setSession } from "../store/account/accountReducer";
import LoadingPage from "../views/loadingPage";
import Rabisco from "../views/rabisco";
import Cotacao from "../views/cotacao/components/QuoteForm";
import Quotes from "../views/cotacao/Quotes";
import SellMiles from "../views/sellMiles";

//@ts-ignore
interface PrivateRouteProps extends RouteProps {
    component: any;
}

function PrivateRoute({ component, ...rest }: PrivateRouteProps) {

    const { user, isLoading } = useAuth();

    return (
        <Routes>
            {
                isLoading
                    ? <Route
                        index
                        element={<LoadingPage />}
                    />
                    : <Route
                        {...rest}
                        index
                        element={user ? component : <Navigate to="/login" />}
                    />
            }
        </Routes>
    );
}

export function AppRoutes() {

    const dispatch = useDispatch();
    const { user, login, setIsLoading } = useAuth()
    const [isLoading, setIsLoadiing] = useState(true)
    useEffect(() => {
        setIsLoadiing(true)
        const localSession = window.sessionStorage.getItem("session")

        if (localSession) {
            const parsedSession = JSON.parse(localSession)
            login(parsedSession)
            setIsLoading(false)
            dispatch(isLoggedIn(true))
            dispatch(setSession(parsedSession))
            setIsLoadiing(false)
        } else {
            login(null)
            setIsLoading(false)
            dispatch(isLoggedIn(false))
            dispatch(setSession(null))
            setIsLoadiing(false)
        }
    }, [])


    return (
        <BrowserRouter>
            <Routes>
                <Route>
                    <Route path="/" index element={<Home />} />
                </Route>
                <Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/criar-conta" element={<CreateAccount />} />
                </Route>
                <Route
                    path="/task"
                    element={
                        <PrivateRoute
                            component={<Task />}
                        />
                    } />
                <Route
                    path="/conversor"
                    element={
                        <PrivateRoute
                            component={<ConversorView />}
                        />
                    }
                />
                <Route
                    path="/rabisco"
                    element={<Rabisco />}
                />
                <Route
                    path="/cotacao"
                    element={
                        <PrivateRoute
                            component={
                                <Quotes />
                            }
                        />
                    }
                />
                <Route
                    path="/cotacao/:id"
                    element={
                        <PrivateRoute
                            component={
                                <Cotacao />
                            }
                        />
                    }
                />
                <Route
                    path="/cotacao/criar"
                    element={
                        <PrivateRoute
                            component={
                                <Cotacao />
                            }
                        />
                    }
                />
                <Route
                    path="/vender"
                    element={
                        <PrivateRoute
                            component={
                                <SellMiles />
                            }
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}
