import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
export default function useFail() {

    const notify = () => toast("Wow so easy !");

    return (message: string) => toast(message, {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: "zoom",
        theme: "dark",
    });
}