import React, { ReactElement } from "react";
import style from "./Container.module.scss";
import TextArea from "../Typography/TextArea";

export type Props = {
	children: ReactElement
	heading?: ReactElement,
	title: string
	navHeading?: ReactElement
	leftContent?: ReactElement
	rightContent?: ReactElement
	noPadding?: boolean
	controll?: ReactElement
	footer?: ReactElement
}

export default function Container({
	children,
	heading,
	title,
	navHeading,
	leftContent,
	rightContent,
	noPadding,
	controll,
	footer
}: Props) {

	return (
		<>
			<div
				className={style["container"]}
			>
				<div className={style["container__heading"]}>
					<TextArea title={title} />
					{
						navHeading && <div className={style["container__heading__nav"]}>
							{navHeading}
						</div>
					}
					{
						controll &&
						<div className={style["container__controll"]}>
							{controll}
						</div>
					}

					{/* <div className={style["breadcrumb"]}><p>/ board </p></div> */}
				</div>

				<div
					className={style["container__children"]}
					data-no-padding={noPadding}
				>
					{children &&
						children
					}

				</div>
				{footer &&
					<div
						className={style["container__footer"]}
						data-no-padding={noPadding}
					>
						{footer}

					</div>
				}
				{leftContent && <div className={style["left-content"]}>
					{leftContent}
				</div>}
				{rightContent && <div className={style["right-content"]}>
					{rightContent}
				</div>}
			</div>
		</>
	)
} 