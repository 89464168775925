import { useDispatch } from "react-redux"
import useLoadTasks from "./useLoadTasks"
import { setLoaded, setLoading, setTasks } from "../../store/tasks/taskReducer"
import { Category } from "../../@types/Task/Task"

export default function useTasks () {

    const loadTasks = useLoadTasks()
    const dispatch = useDispatch()

    return async ({category}: {category?: Category["id"][]}) => {

        try {
            
            dispatch(setLoading(true))

            const response = await loadTasks({category})

            console.log("🚀 ~ file: useTasks.ts:23 ~ return ~ response:", response);

            
            dispatch(setTasks(response.data))
            dispatch(setLoading(false))
            dispatch(setLoaded(true))
            
        } catch (error) {

            console.log("🚀 ~ file: useTasks.ts:21 ~ return ~ error:", error);

            dispatch(setLoading(false))
            
            throw error

        }
    }
}