import { BiSolidUser, BiUser } from "react-icons/bi";
import Menu from "../menu";
import style from "./Navbar.module.scss";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../store/IStore";
import { useEffect } from "react";
import { isLoggedIn, setSession, user } from "../../store/account/accountReducer";
import { Nav as NavType, NavOptions, setActiveOption } from "../../store/nav/navReducer";
import { useAuth } from "../../authProvider";

export default function Navbar() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, login, setIsLoading } = useAuth();

    /* useEffect(() => {
        console.log(user)
        const localSession = window.sessionStorage.getItem("session")

        if (localSession) {
            const parsedSession = JSON.parse(localSession)
            login(parsedSession)
            setIsLoading(false)
            dispatch(isLoggedIn(true))
            dispatch(setSession(parsedSession))
        }
    }, []) */

    const accountReducer = useSelector((state: IStore) => state.account)
    const isUserLoggedIn = accountReducer.isLoggedIn
    const session = accountReducer.session

    return (
        <div className={style["navbar"]}>
            <Menu type="aside" />
            <div className={style["navbar__logo"]}>
                <h1>Milhas Zen</h1>
            </div>
            <div className={style["navbar__account"]}>
                {
                    isUserLoggedIn
                        ? <span className={style["navbar__account--link"]}>
                            {`Welcome ${session?.username}`}
                        </span>
                        : <Button
                            className={style["navbar__account--link"]}
                            buttonType="outline-secondary"
                            onClick={() => navigate("/login")}
                        >
                            SignUp
                        </Button>
                }
                <div className={style["navbar__account--img"]}>
                    <BiSolidUser></BiSolidUser>
                </div>
            </div>
        </div>
    )
}


// todo isolar componente
type PropsNav = {
    activeOption: NavType | null,
}

export function Nav() {
    const navReducer = useSelector((state: IStore) => state.nav)
    const options = navReducer.options
    const activeOption = navReducer.activeOption

    const dispatch = useDispatch()
    return (
        <div className={style["nav"]}>
            <ul>
                {
                    options?.map((opt) =>
                        <li
                            data-active={activeOption?.id === opt.id}
                            onClick={() => dispatch(setActiveOption({ id: opt.id, label: opt.label }))}
                        >
                            {opt.label}
                        </li>

                    )
                }
            </ul>
        </div>
    )
}