import axios from "axios"
import useGet from "../http/useGet"
import { Category } from "../../@types/Task/Task"

export default function useLoadTasks() {

    const get = useGet()

    return async ({ category }: { category?: Category["id"][] }) => {
        const strCategory = JSON.stringify(category)
        try {

            const response = await get(`/tasks?${category ? `category=${strCategory}` : ""}`)
            
            console.log("🚀 ~ file: useLoadTasks.ts:16 ~ return ~ response:", response);
            return response


        } catch (error) {

            console.log("🚀 ~ file: useLoadTasks.tsx:16 ~ return ~ error:", error);
            return error

        }
    }
}