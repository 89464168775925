import { ReactNode } from "react"
import style from "./ExpandContainer.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../store/IStore"
import Overlay from "../overlay"
import { setExpandContainer } from "../../store/expandContainer"

type PropsExpandContainer = {
    title?: string
    children?: ReactNode
}

export default function ExpandContainer({ title, children }: PropsExpandContainer) {

    const view = useSelector((state: IStore) => state.expandContainer.view)

    const dispatch = useDispatch()
    return (
        <>
            <div
                data-view={view}
                className={style["expand-container"]}>
                {title && <h3>{title}</h3>}
                {children && <div className={style["expand-container__children"]}>
                    {children}
                </div>}
            </div>
            <Overlay
                view={view}
                onClick={() => dispatch(setExpandContainer(false))}
            />
        </>
    )
}