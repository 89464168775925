import React from 'react';
import style from "./Input.module.scss"
import Select from "react-select";
import { number } from 'prop-types';

export interface InputProps {
    label?: string;
    icon?: any;
    name: string;
    type?: "text" | "number" | "date" | "select" | "color"
    options?: { label: string, value: string | number }[]
    placeholder?: string
    error?: boolean
    errorMessage?: string,
    value?: string | number,
    direction?: "column" | 'row',
    defaultValue?: string | number
}

function InputRHFComponent({
    label,
    name,
    icon,
    placeholder,
    type,
    options,
    error,
    errorMessage,
    direction,
    defaultValue,
    ...args

}: InputProps, ref: any) {

    console.log("🚀 ~ file: input.tsx:29 ~ options:", options);

    //console.log(name)
    return (
        <div className={style["input-wrapper"]}>
            <div
                className={style["input"]}
                data-direction={direction}
            >
                {direction === "column" && <div className={style["input__label"]} style={{ display: "flex" }}>
                    <span className={style["input__icon"]}>{icon}</span>
                    {!!label && <label style={{ flex: 1 }} className={style["input__label"]}>{label}</label>}</div>}
                {direction === "row" && <div style={{ display: "flex" }}>
                    <span className={style["input__icon"]}>{icon}</span>
                    {!!label && <label style={{ flex: 1 }} className={style["input__label"]}>{label}</label>}</div>}
                <div className={style["input__input"]}>
                    {
                        type === "text" &&
                        <input
                            {...args}
                            name={name}
                            type={type}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            ref={ref}
                        />
                    }
                    {
                        type === "date" &&
                        <input
                            {...args}
                            name={name}
                            placeholder={placeholder}
                            type={type}
                            defaultValue={defaultValue}
                            ref={ref}
                        />
                    }
                    {
                        type === "color" &&
                        <input
                            {...args}
                            name={name}
                            placeholder={placeholder}
                            type={type}
                            defaultValue={defaultValue}
                            ref={ref}
                        />
                    }
                    {
                        type === "number" &&
                        <input
                            {...args}
                            name={name}
                            placeholder={placeholder}
                            type={type}
                            defaultValue={defaultValue}
                            ref={ref}
                        />
                    }
                    {
                        type === "select" &&
                        <select
                            {...args}
                            name={name}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            ref={ref}
                        >
                            {options?.map(opt => (
                                <option value={opt.value}>
                                    {opt.label}
                                </option>
                            ))}
                        </select>
                    }
                    {error && <div className={style["input__error-wrapper"]}><p className={style["input__error"]}>{errorMessage}</p></div>}
                </div>
            </div>
        </div>
    )
}

const Input = React.forwardRef(InputRHFComponent);

export default Input;