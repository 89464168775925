import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { setLoading } from './store/tasks/taskReducer';

type User = {
    token: string
    username: string
    email: string
} | null

type AuthContextType = {
    user: User | null;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    login: (user: User) => void;
    logout: () => void;
}

export function useAuth(): AuthContextType {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth deve ser usado dentro de um AuthProvider');
    }
    return context;
}
export const defaultContext: AuthContextType = {
    user: {
        token: '',
        email: '',
        username: ''
    },
    isLoading: true,
    setIsLoading: () => { },
    login: () => { },
    logout: () => { },

}

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthContext = React.createContext(defaultContext);


export function AuthProvider({ children }: AuthProviderProps) {

    const [user, setUser] = React.useState<User | null>(null)
    const [isLoading, setIsLoading] = React.useState(true)


    const login = (user: User) => {
        setLoading(false)
        return setUser(user)
    }

    const logout = () => {
        setUser(null)
    }

    const authContextValue: AuthContextType = {
        user,
        isLoading,
        setIsLoading,
        login,
        logout,
    };
    return (
        <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
    )
}