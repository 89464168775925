import axios from "axios"

export default function useLoadCategoryTasks() {


    return async () => {

        try {

            const response = await axios.get("http://localhost:4000/api/task-categories")

            return response.data

        } catch (error) {

            console.log("🚀 ~ file: useLoadTasks.tsx:16 ~ return ~ error:", error);

            throw error;
        }
    }
}