import { configureStore } from '@reduxjs/toolkit'
import tasksReducer from "./tasks/taskReducer" 
import accountReducer from "./account/accountReducer"
import expandContainerReducer from './expandContainer'
import navReducer from './nav/navReducer'
import userQuote from './userQuote'
import sellerOffers from './sellerOffers'

const store = configureStore({
  reducer: {
    account: accountReducer,
    tasks: tasksReducer,
    expandContainer: expandContainerReducer,
    nav: navReducer,
    userQuote: userQuote,
    sellerOffers: sellerOffers
  },
})

export default store