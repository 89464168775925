import { useEffect, useState } from "react";
import List from "../../../components/List";
import Button from "../../../components/button";
import style from './Category.module.scss'
import axios from "axios";
import useCategoryTasks from "../../../hooks/category/useCategoryTasks";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../store/IStore";
import { BiCategory } from "react-icons/bi"
import { Category } from "../../../@types/Task/Task";
import { setCategoriesTasks } from "../../../store/tasks/taskReducer";
import useTasks from "../../../hooks/tasks/useTasks";
import { toast } from "react-toastify";
import { MdSettings } from "react-icons/md";
import FormCategory from "./components/FormCategory";


export default function CategoryView() {

    const [createCategory, setCreateCategory] = useState(false)

    const categoriesTasksReducer = useSelector((state: IStore) => state.tasks.categories)
    const categories = categoriesTasksReducer.categoriesTasks
    const loadedCategories = categoriesTasksReducer.loadedCategoriesTasks
    const loadCategoryTasks = useCategoryTasks()
    const loadTask = useTasks()

    const [form, setForm] = useState<Category["id"][]>([])
    useEffect(() => {
        (async () => {
            if (!loadedCategories) {
                await loadCategoryTasks()
            }
        })()
    }, [])

    useEffect(() => {
        loadTask({ category: form })
    }, [form])


    return (
        <div className={style["category"]}>
            <div className={style["category__head"]}>
                <div>
                    <BiCategory size={24} />
                    <h3>Categorias</h3>
                    <Button
                        buttonType="button-icon-2"
                        onClick={() => setCreateCategory(true)}
                    >+</Button>
                </div>
            </div>
            <div className={style["category__body"]}>
                <List
                    component={<LeftListItem />}
                    array={categories}
                    dataKey="category"
                    componentProps={{ form, setForm }}
                />
                <div
                    className={style["category__new-category"]}
                    data-view={createCategory}
                    onBlur={() => {
                        setTimeout(() => {
                            setCreateCategory(false)
                        }, 4000)

                    }}
                >
                    <FormCategory />
                </div>
            </div>
        </div>
    )
}


type leftListItem = {
    category?: Category,
    form?: Category[],
    setForm?: any
}
const LeftListItem = ({ category, form, setForm }: leftListItem) => {

    const [hover, setHover] = useState(false)
    const [isEdditing, setIsEdditing] = useState(false)
    const categories = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)
    const dispatch = useDispatch()

    const [categoryText, setCategoryText] = useState(category?.category_text)
    const [categoryColor, setCategoryColor] = useState(category?.category_color)

    const handleSelectCategory = async (category: Category | undefined, e: any) => {
        e.preventDefault()

        if (!form) return
        if (!category) throw Error("categoria invalida")

        try {
            const response = await axios.put("http://localhost:4000/api/task-category", category)

            dispatch(setCategoriesTasks(response.data))
            return response

            //todo alterar categoria quando receber repsonse

        } catch (error: any) {

            console.log("🚀 ~ file: index.tsx:142 ~ handleSelectCategory ~ error:", error);
            toast(error.message)

        }

        const alreadySelected = form?.findIndex(cat => cat.id === category.id)

        if (alreadySelected !== -1) {
            const newForm = form?.filter(cat => cat.id !== category.id)
            setForm(newForm)
            return
        } else {
            setForm([...form, category])
            return
        }
    }

    return (
        <>
            <div
                className={style["category__item"]}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <form
                    data-visibility={!isEdditing}
                    action=""
                    className={style["category__item--category"]}

                >
                    <input
                        id={category?.category_text}
                        checked={category?.checked}
                        type="checkbox"
                        onChange={(e) => handleSelectCategory(category, e)}
                    />
                    <label htmlFor={category?.category_text}>{category?.category_text}</label>
                </form>

                <MdSettings
                    data-active={hover}
                    className={style["category__item__icon"]}
                    onClick={() => setIsEdditing(true)}
                />
            </div>
            <div
                className={style["category__item--update-category"]}
                data-visibility={isEdditing}
            >
                <FormCategory
                    category={category}
                    cb={(response: any) => {
                        console.log(response)
                        setIsEdditing(false)
                    }}
                />
            </div>
        </>
    )
}



