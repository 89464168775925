import { ReqParams } from "../../@types/ReqParams"
import useGet from "../http/useGet"
import useFail from "../notices/useFail"

export default function useLoadSellerOffers() {

    const get = useGet()
    const fail = useFail()

    return async ({ filters, limit, offset}:ReqParams) => {

        try {

            const response = await get(`/seller-offers?limit=${limit && limit}&offset=${offset && offset}&filters=${filters && filters}`)

            return response
        } catch (error: any) {
            fail(error.message)
        }
    }
};
