import { ButtonHTMLAttributes } from "react";
import style from './Button.module.scss'
import classNames from "classnames";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    direction?: "column" | "row"
    buttonType?: "primary" | "secondary" | "black-1" | "black-2" | "button-icon" | "button-icon-2" | "outline-primary" | "outline-secondary"
    icon?: any
}

const Button: React.FC<ButtonProps> = ({ children, buttonType, icon, className, ...buttonProps }) => {

    return (
        <button
            {...buttonProps}
            data-buttonType={buttonType ? buttonType : "primary"}
            className={classNames({ [style["button"]]: true, [`${className}`]: className })}
            children={
                <>
                    {!!icon && icon}
                    {children}
                </>
            }
        />
    );
};

export default Button;