import { useDispatch } from "react-redux"
import useLoadSellerOffers from "./useLoadSellerOffers"
import { setData, setLoading } from "../../store/sellerOffers";
import { ReqParams } from "../../@types/ReqParams";

export default function useSellerOffers() {
    
    const dispatch = useDispatch()
    const loadSellerOffers = useLoadSellerOffers();
    
    return async ({filters, limit, offset}: ReqParams) => {

        dispatch(setLoading(true))
        
        try {
            const response = await loadSellerOffers({filters, limit, offset})
            dispatch(setData(response))
            
        } catch (error: any) {

            console.log(error.message)
        } finally {
            
            dispatch(setLoading(false))

        }
    }
};
