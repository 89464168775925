import { useEffect, useState } from "react";
import style from "./rabisco.module.scss";

export default function Rabisco() {

    const movement = 10
    const [snakePosition, setSnakePosition] = useState({
        top: 10,
        left: 20
    })
    var jogo = {};
    const snake = "#snake"

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            console.log(e.key)

            if (e.key === "ArrowUp") {
                var topo = parseInt($("#snake").css("top"));
                $("#snake").css("top", topo - 10);
            }
            if (e.key === "ArrowDown") {
                var topo = parseInt($("#snake").css("top"));
                $("#snake").css("top", topo + 10);
            }
            if (e.key === "ArrowRight") {
                var topo = parseInt($("#snake").css("left"));
                $("#snake").css("left", topo + 10);
            }
            if (e.key === "ArrowLeft") {
                var topo = parseInt($("#snake").css("left"));
                $("#snake").css("left", topo - 10);
            }
        })
    }, [])

    return (
        <div className={style.container}>
            <div className={style.rabisco}>

            </div>
            <div
                id="snake"
                className={style.snake}
            ></div>
        </div>
    )
}