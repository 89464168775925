import List from "../../../components/List"
import Button, { ButtonProps } from "../../../components/button"
import style from "./boardTask.module.scss"
import { Task, Task as TaskType } from "../../../@types/Task/Task";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../store/IStore";
import { AiOutlineEdit } from "react-icons/ai";
import Card from "../../../components/card";
import axios from 'axios'
import { setExpandContainer } from "../../../store/expandContainer";
import { setActiveTask } from "../../../store/tasks/taskReducer";

export default function BoardTask() {

    const [createTask, setCreateTask] = useState(false)
    const [view, setView] = useState(false)
    const [viewModalStatus, setViewModalStatus] = useState(false)

    const tasksReducer = useSelector((state: IStore) => state.tasks.tasks)
    const tasks = tasksReducer.tasks
    const activerTask = tasksReducer.activeTask
    const loadedTasks = tasksReducer.loadedTasks
    const loadingTasks = tasksReducer.loadingTasks

    const taskStatusReducer = useSelector((state: IStore) => state.tasks.status)
    const statusTask = taskStatusReducer.statusTasks
    const loadingStatusTask = taskStatusReducer.loadingStatusTasks
    const loadedStatusTask = taskStatusReducer.loadedStatusTasks

    const categories = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)

    return (

        <div className={style["task"]}>
            {
                statusTask.map((status) => {

                    const filteredTasksByStatus = tasks?.filter(t => t.status === status.id)

                    return (
                        <div className={style["board"]}>
                            <div className={style["board__head"]}>
                                <h3>{status.status_text}</h3>
                                <Button
                                    className={style["board__button"]}
                                    buttonType="button-icon"
                                    onClick={() => setViewModalStatus(true)}
                                    children="+"
                                />
                            </div>
                            <TaskList
                                st={status}
                                tasks={filteredTasksByStatus}
                                setView={setView}
                                setCreateTask={setCreateTask}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

type PropsHead = {
    setView: any
    setCreateTask: any
    tasks: TaskType[]
    st: any
}

const TaskList = ({ setView, setCreateTask, st, tasks }: PropsHead) => {

    const [buttonView, setButtonView] = useState(false)
    if (!tasks) return null

    const filteredTasks = tasks.filter((task: TaskType) => {
        return task.status === st.id
    })

    return (
        <List
            className={style["board__list"]}
            array={filteredTasks}
            component={<Component setView={setView} />}
            dataKey="task"
            itemMargin={"small"}
        />
    )
}



type ComponentProps = {
    task?: TaskType,
    setView: any
}

const Component = ({ task, setView }: ComponentProps) => {

    const categories = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)
    const category = categories.find(c => c.id == task?.category)
    const dispatch = useDispatch();

    const handleDelete = async () => {

        const data = { id: task?.id }

        try {
            await axios.delete("http://localhost:4000/api/task", { data })
            const element = document.getElementById(`${task?.id}`)
            element?.remove()
        } catch (error) {

            console.log("🚀 ~ file: index.tsx:268 ~ handleDelete ~ error:", error);
        }
    }
    const btns: ButtonProps[] = [
        {
            buttonType: "button-icon-2",
            icon: <AiOutlineEdit />,
            //onClick: () => setView(true)
        },
        /* {
            buttonType: "button-icon-2",
            icon: <AiOutlineDelete />,
            onClick: handleDelete
        }, */
    ]


    const handleOpenTask = () => {
        dispatch(setExpandContainer(true))
        dispatch(setActiveTask(task))
    }

    return (
        <div
            draggable={true}
            onClick={handleOpenTask}
        >
            <Card
                id={`${task?.id}`}
                title={task?.title}
                text={task?.description}
                startDate={task?.startDate}
                endDate={task?.endDate}
                category={category}
            />
        </div>
    )
}