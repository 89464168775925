import { ReqParams } from "../../@types/ReqParams"
import useGet from "../http/useGet"
import useFail from "../notices/useFail"

export default function useLoadUserQuotes () {

    const get = useGet()
    const fail = useFail()


    return async  ({filters, limit, offset}: ReqParams) => {

        try {

            const response = await get(`/ticket?${`limit=${limit}`}${`&offset=${offset}`}`)

            return response
        } catch (error: any) {
            fail(error.message)
        }
    }
}