import { HTMLAttributes, useState } from 'react';
import style from './Card.module.scss';
import ButtonGroup from '../buttonGroup/ButtonGroup';
import { ButtonProps } from '../button';
import { AiOutlineCheck, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai"
import { BiCategory } from 'react-icons/bi';
import { MdComment, MdOutlineDateRange, MdShortText } from 'react-icons/md';
import { RxTextAlignLeft } from "react-icons/rx"
import { Category } from '../../@types/Task/Task';
import Chip from '../chip/chip';

export interface PropsCard extends HTMLAttributes<HTMLDivElement> {
    text: string | undefined
    status?: string | undefined
    startDate?: string | undefined
    endDate?: string | undefined
    comment?: any[]
    category?: Category | undefined
    controlBtns?: ButtonProps[]

}


export default function Card({
    title,
    text,
    status,
    startDate,
    endDate,
    comment,
    category,
    controlBtns,
    ...args
}: PropsCard) {

    const [mouseEnter, setMouseEnter] = useState(false)

    return (
        <div
            {...args}
            className={style["card"]}
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => setMouseEnter(false)}
        >
            <span className={style["card__title"]}>{title}</span>

            {
                !!controlBtns && mouseEnter &&
                <div className={style["card__controll"]}>
                    <ButtonGroup
                        id={style['card-controll']}
                        buttons={controlBtns}
                        data-view={mouseEnter}
                    />
                </div>
            }
            <span
                className={style["card__text"]}
                data-show={false}
            >
                <MdShortText size={16}></MdShortText>
                <p>{text}</p>
            </span>
            {
                !!category && <span className={style["card__category"]}>
                    <BiCategory size={16}></BiCategory>
                    <Chip message={category?.category_text} color={category?.category_color} />
                </span>
            }
            {
                !!startDate && <span className={style["card__startDate"]}>
                    <MdOutlineDateRange size={16}></MdOutlineDateRange>
                    <p>{startDate}</p>
                </span>
            }
            {
                !!endDate && <span className={style["card__endDate"]}>
                    <MdOutlineDateRange size={16}></MdOutlineDateRange>
                    <p>{endDate}</p>
                </span>
            }
            {
                !!comment && <span className={style["card__comment"]}>
                    <MdComment size={16}></MdComment>
                    <p>{endDate}</p>
                </span>
            }
            {status && <span className={style["card__status"]}>{status}</span>}
        </div>
    )
}