import { useEffect } from "react"
import { Pagination as PaginationType } from "../../@types/Pagination"
import style from "./Pagination.module.scss"

export default function Pagination({
    pagination,
    goTo
}: {
    pagination: PaginationType,
    goTo: any
}) {

    const pages = []

    for (let i = 0; i < Number(pagination.pages); i++) {
        const pageValue = i + 1
        pages.push(pageValue)
    }


    useEffect(() => {

        document.querySelector(`.${style["page--active"]}`)?.classList.remove(style["page--active"])

        const currentPage = document.querySelector(`[data-page="${pagination.currentPage}"]`)
        currentPage?.classList.add(style["page--active"])
    }, [pagination.currentPage])


    return (

        <div className={style["wrapper"]}>
            {
                <p>
                    Exibindo {Number(pagination.offset) + 1}-{(Number(pagination.currentPage)) * Number(pagination.limit) < Number(pagination.results) ? (Number(pagination.currentPage)) * Number(pagination.limit) : pagination.results} de {pagination.results} resultados.
                </p>
            }
            <div
                id="pagination"
                className={style["pagination"]}
            >
                {pages.map((p) => {
                    return (
                        <div
                            data-page={p}
                            className={style["page"]}
                            onClick={() => goTo(p)}
                        >
                            {p}
                        </div>
                    )
                })}
            </div>
        </div>
    )
};
