import * as React from 'react';
import { ReactNode, useState } from 'react';
import ButtonGroup from '../../../components/buttonGroup/ButtonGroup';
import style from "../Login.module.scss";
import useSuccess from '../../../hooks/notices/useSuccess';
import useFail from '../../../hooks/notices/useFail';
import usePost from '../../../hooks/http/usePost';
import { useNavigate } from 'react-router-dom';

export default function CreateAccount() {

    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [repeatPassword, setRepeatPassword] = useState(undefined);
    const [name, setName] = useState(undefined)
    const [sobrenome, setSobrenome] = useState(undefined)

    const [createdAccount, setCreatedAccount] = useState(false)

    const success = useSuccess()
    const fail = useFail();
    const post = usePost();
    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {

        e.preventDefault()

        try {

            if (password !== repeatPassword) fail("Senhas não coincidem")

            const data = {
                email,
                password,
                name,
                surname: sobrenome
            }

            const response = await post("/account-service", data)

            setCreatedAccount(true)

            if (response) {
                console.log({ response })
            }

        } catch (error: any) {

            console.log("🚀 ~ file: index.tsx:20 ~ handleSubmit ~ error:", error);
            fail(error.message)
        }
    }


    return (
        <div className={style["create-account"]}>
            <div className={style["create-account__form-wrapper"]}>
                {createdAccount
                    ? <SuccesCreatedAccount />
                    : <form action="">
                        <h3>Criar conta</h3>
                        <label htmlFor="name">Nome</label>
                        <input
                            id="name"
                            type="text"
                            required
                            value={name}
                            onChange={(e: any) => setName(e.target.value)}
                        />
                        <label htmlFor="name">Sobrenome</label>
                        <input
                            id="sobrenome"
                            type="text"
                            required
                            value={sobrenome}
                            onChange={(e: any) => setSobrenome(e.target.value)}
                        />
                        <label htmlFor="name">Email</label>
                        <input
                            id="email"
                            type="text"
                            required
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                        />
                        <label htmlFor="name">Senha</label>
                        <input
                            id="password"
                            type="password"
                            required
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                        />
                        <label htmlFor="name">Repita a senha</label>
                        <input
                            id="repeat-password"
                            type="password"
                            required
                            value={repeatPassword}
                            onChange={(e: any) => setRepeatPassword(e.target.value)}
                        />

                        <ButtonGroup
                            className={style["login-page__form__controll"]}
                            direction='row'
                            buttons={[
                                {
                                    buttonType: "secondary",
                                    children: "Voltar",
                                    type: "button",
                                    onClick: () => navigate("/login")
                                },
                                {
                                    buttonType: 'primary',
                                    children: "Criar conta",
                                    onClick: (e) => handleSubmit(e),
                                    type: "button"
                                }
                            ]}
                        />
                    </form>
                }
            </div>
        </div>
    )
}

const SuccesCreatedAccount = () => {
    return (
        <div>
            <h3>Conta Criada!</h3>
            <p>Verifique o link que enviamos para seu email para ativar sua conta.</p>
        </div>
    )
}
