import axios from "axios"
import { useState } from "react"
import Button from "../../../components/button"
import Modal from "../../../components/modal"
import TextArea from "../../../components/Typography/TextArea"
import style from "./CreateTask.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../store/IStore"
import { pushTask, setModalCreateTask } from "../../../store/tasks/taskReducer"
import usePost from "../../../hooks/http/usePost"

type Props = {
    view: boolean,
    setView: any
}

export default function CreateTask({ view, setView }: Props) {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState<{
        title: string | undefined,
        description: string | undefined,
        startDate: string | undefined,
        endDate: string | undefined,
        status: number | undefined
        category: number | undefined
    }>({
        title: undefined,
        description: undefined,
        startDate: undefined,
        endDate: undefined,
        status: undefined,
        category: undefined
    })
    const categoriesTasks = useSelector((state: IStore) => state.tasks.categories.categoriesTasks)
    const statusTasks = useSelector((state: IStore) => state.tasks.status.statusTasks)

    const dispatch = useDispatch();
    const post = usePost();

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        try {
            setLoading(true)
            const response = await post("/task", form)

            dispatch(pushTask(response))
            dispatch(setModalCreateTask(false))
            setLoading(false)
        } catch (error) {
            setLoading(false)

            console.log("🚀 ~ file: index.tsx:27 ~ handleSubmit ~ error:", error);


        }
    }

    return (
        <Modal
            view={view}
            setView={() => dispatch(setModalCreateTask(false))}
        >
            <form action="">
                <TextArea
                    title="Criar tarefa"
                />
                <div className={style["input"]}>
                    <label htmlFor="task-name">Nome:</label>
                    <input
                        id="task-name"
                        type="text"
                        value={form.title}
                        onChange={(e) => setForm({ ...form, title: e.target.value })}
                    />
                </div>
                <div className={style["input"]}>dsdadasd
                    <label htmlFor="task-description">Descrição:</label>
                    <input
                        id="task-description"
                        type="text"
                        value={form.description}
                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                    />
                </div>
                <div className={style["input--row"]}>
                    <div className={style["input"]}>
                        <label htmlFor="task-start-date">Data de início:</label>
                        <input
                            id="task-start-date"
                            type="date"
                            value={form.startDate}
                            onChange={(e) => setForm({ ...form, startDate: e.target.value })}
                        />
                    </div>
                    <div className={style["input"]}>

                        <label htmlFor="task-end-date">Data final:</label>
                        <input
                            id="task-end-date"
                            type="date"
                            value={form.endDate}
                            onChange={(e) => setForm({ ...form, endDate: e.target.value })}
                        />
                    </div>

                </div>
                <div className={style["input"]}>

                    <label htmlFor="task-category">Categorias:</label>
                    <select
                        id="task-category"
                        title="select-category"
                        name="select-category"
                        onChange={(e) => setForm({ ...form, category: Number(e.target.value) })}

                    >
                        {categoriesTasks.map((c) =>
                            <option value={c.id}>
                                {c.category_text}
                            </option>
                        )}
                    </select>

                </div>
                <div className={style["input"]}>
                    <label htmlFor="task-status">Status:</label>
                    <select
                        id="task-status"
                        title="select-status"
                        name="select-status"
                        onChange={(e) => setForm({ ...form, status: Number(e.target.value) })}
                    >
                        {
                            statusTasks.map((st) => {
                                return (
                                    <option value={st.id}>{st.status_text}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className={style["button-group"]}>
                    <Button
                        buttonType="secondary"
                        onClick={() => dispatch(setModalCreateTask(false))}
                    >Cancelar</Button>
                    <Button
                        type="button"
                        onClick={(e) => handleSubmit(e)}
                        buttonType="primary"
                    >
                        {loading ? "Loading..." : "Criar"}
                    </Button>
                </div>
            </form>
        </Modal>
    )

}